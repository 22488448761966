import React from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { utils, writeFile } from 'xlsx';

import { Grid, Stack, Button, ToggleButtonGroup, ToggleButton, IconButton, Card } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import http from '../../../api/http';
import Context from '../../../context';
import { formatDateJS } from '../../../utils/func';
import GenericForm from '../../../components/GenericForm';

const RowCollaapse = ({ row, googleAddress }) => {

    const queryClient = useQueryClient();
    const { snackbar, confirm, popup } = React.useContext(Context);

    const updateCustomerPrice = useMutation((obj) => http.put(`customers/price/${row.customers_id}`, obj), {
        onSuccess: (data) => {
            queryClient.refetchQueries(['getCustomersForManager'], { active: true, exact: true });
            snackbar('מחירי לקוח עודכנו בהצלחה', 'success');
        },
        onError: (error) => {
            snackbar("אירעה שגיאה בעידכון מחירים", 'error');
        }
    });

    const getCustomerPriceReport = useMutation((obj) => http.get(`customers/price-report-for-manager/${row.users_companies_id}/${obj.date_start}/${obj.date_end}`), {
        onSuccess: (data) => {
            console.log(data);

            const wb = utils.book_new();
            const ws = utils.json_to_sheet(data[0]);
            utils.book_append_sheet(wb, ws, 'סיכום חודשי');
            writeFile(wb, `דו"ח חודשי ${row.customers_name}.xlsx`);
        },
        onError: (error) => {
            snackbar("אירעה שגיאה בהפקת קובץ", 'error');
        }
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <Card elevation={0} sx={{ p: 2 }}>
                    <GenericForm
                        fields={[
                            { type: "line", label: "עדכון מחירים ללקוח" },
                            { name: 'price', label: "מחיר משלוח", type: 'currencyIL' },
                            { name: 'packege', label: "חבילה נוספת", type: 'currencyIL' },
                            { name: 'collect', label: "תוספת לגוביינא", type: 'currencyIL' },
                            { name: 'express', label: "תוספת לאקספרס", type: 'currencyIL' },
                            { name: 'd_double', label: "תוספת לכפול", type: 'currencyIL' },
                            { label: "עדכון", type: 'submit', variant: 'contained' },
                        ]}
                        initInputs={{
                            price: row.price,
                            packege: row.packege,
                            collect: row.collect,
                            express: row.express,
                            d_double: row.d_double
                        }}
                        onSubmit={(inputs) => {
                            updateCustomerPrice.mutate(inputs);
                        }}
                    />
                </Card>
            </Grid>
            <Grid item xs={2}>
                <Card elevation={0} sx={{ p: 2 }}>
                    <GenericForm
                        fields={[
                            { type: "line", label: 'דו"ח תשלומים ללקוח' },
                            { name: 'date_start', label: "מתאריך", type: 'date', required: true },
                            { name: 'date_and', label: "עד תאריך", type: 'date', required: true },
                            { label: 'הפקת דו"ח', type: 'submit', variant: 'contained' },
                        ]}
                        onSubmit={(inputs) => {
                            getCustomerPriceReport.mutate({
                                date_start: formatDateJS(inputs.date_start),
                                date_end: formatDateJS(inputs.date_and)
                            });
                        }}
                    />
                </Card>
            </Grid>
        </Grid>
    )
}

export default RowCollaapse;

// check if if value is empty
const isEmpty = (value) => {
    return value == undefined || value == '' || value == null ? true : false;
}