import React from 'react'

import {IconButton, Tooltip} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CircleIcon from '@mui/icons-material/Circle';

const IconBtn = ({ icon, onClick, tooltip, disabled }) => {

    const theme = useTheme();

    const btn = <IconButton size='small'
        disabled={disabled}
        onClick={onClick}
        sx={{
            borderRadius: '5px',
            boxShadow: theme.customShadows.z1,
            backgroundColor: theme.palette.primary[100],
            color: theme.palette.primary.main,
            p: 2.5,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.default,
            },
        }}>
        {icon}
    </IconButton>

    return (
        <>
            {!tooltip || disabled ? btn : <Tooltip title={tooltip} arrow>{btn}</Tooltip>}
        </>
    )
}

// default props
IconBtn.defaultProps = {
    icon: <CircleIcon fontSize='small' />,
    onClick: () => {
        console.log('click')
    },
    tooltip: null,
    disabled: false
}

export default IconBtn