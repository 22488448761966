import React, { useState, useEffect, useRef } from "react";
import {TextField} from '@mui/material';

function SearchLocationInput({id, onSelect, onFocus}) {
  
  const [query, setQuery] = useState("");

  let autoComplete;

  function handleLoad() {
    autoComplete = new window.google.maps.places.Autocomplete(
      document.getElementById(id),
      { types: ["geocode"] }
    );
    autoComplete.setFields(["address_components", "formatted_address"]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect()
    );
  }
  
  async function handlePlaceSelect() {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    setQuery(query);
    let obj = {};
    let area = addressObject.address_components.find(x => x.types.includes("administrative_area_level_1"));
    let city = addressObject.address_components.find(x => x.types.includes("locality"));
    let street = addressObject.address_components.find(x => x.types.includes("route"));
    let number = addressObject.address_components.find(x => x.types.includes("street_number"));
    obj[`area`] = area ? area.long_name : '';
    obj[`city`] = city ? city.long_name : '';
    obj[`street`] = street ? street.long_name : '';
    obj[`num`] = number ? number.long_name : '';
    onSelect(obj)
    //console.log(addressObject);
  }

  useEffect(() => {
    handleLoad()
  }, []);

  return (
      <TextField
        size="small"
        id={id}
        fullWidth
        onChange={event => setQuery(event.target.value)}
        onFocus={onFocus}
        placeholder="הכנס כתובת מלאה.."
        value={query}
        InputProps={{sx:{p:0.1}}}
      />
    
  );
}

export default SearchLocationInput;