import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Paper, Typography, Stack, Box, TextField, Button } from '@mui/material';

import ShowTracking from './ShowTracking';

import http from '../../../api/http';
import Context from '../../../context';




const TrackingDelivery = () => {

    const { id } = useParams();
    const { getObj } = React.useContext(Context);

    const [idToSearch, setIdToSearch] = React.useState(null);
    React.useEffect(() => setIdToSearch(id), [id]);
 
    React.useEffect(() => {
        if (idToSearch) {
            window.history.replaceState(null, null, `/tracking-delivery/${idToSearch}`);
        }
    }, [idToSearch]);

    const gatForTrackingDelivery = useQuery(['gatForTrackingDelivery'],
        () => http.get('global/tracking-delivery/' + idToSearch),
        {
            enabled: idToSearch ? true : false,
        }
    );

    const data = useMemo(() => {
        if (gatForTrackingDelivery.isSuccess) {
            return gatForTrackingDelivery.data[0][0] || {};
        } else {
            return null;
        }
    }, [gatForTrackingDelivery.data]);

    console.log("data:", data)
    return (

        <Stack direction="column" justifyContent="center" alignItems="center" alignContent="center" spacing={2} sx={{ width: 1, height: "90vh" }}>
            <Paper elevation={0} sx={{ p: 2 }}>
                <TextField
                    id=""
                    label="מספר משלוח"
                    variant="outlined"
                    size="large"
                    value={idToSearch || ''}
                    onChange={(e) => setIdToSearch(e.target.value)}
                />
                <Button
                    variant="contained"
                    size="large"
                    sx={{ ml: 2 }}
                    onClick={() => gatForTrackingDelivery.refetch(idToSearch)}
                >
                    חיפוש
                </Button>
            </Paper>
            <Stack direction="row" alignItems="center" justifyContent="center" alignContent="center" spacing={2}  >
                {data && <Box>
                    {data.status && <Box>
                        <Typography variant="subtitle1" align="center" sx={{ p: 2, my: 1, color: "white", bgcolor: `${getObj("global_status", data.status).color}.main` }}>
                            {getObj('global_status', data.status).name}
                        </Typography>

                    </Box>}
                    <Stack direction="row" alignItems="center" justifyContent="center" alignContent="center" spacing={5}>
                        {data.pick_up_time && <Box>
                           <ShowTracking data={data.pick_up_time} title={"איסוף"}/>
                        </Box>}
                        {data.delivery_time && <Box>
                            <ShowTracking data={data.delivery_time} title={"מסירה"}/>
                        </Box>}
                        {data.return_time && <Box>
                            <ShowTracking data={data.return_time} title={"החזרה"}/>

                        </Box>}
                    </Stack>
                </Box>}
            </Stack>
        </Stack>
    )
}

export default TrackingDelivery;


