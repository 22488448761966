import React from 'react'

import { FormControl, FormHelperText, Select, InputLabel, MenuItem, Tooltip, Box, LinearProgress } from '@mui/material'

const GenericSelect = ({
    name,
    label,
    options,
    required,
    error,
    disabled,
    inputs,
    handleChange,
    tooltip,
    validate,
    onBlur,
    first = true,
}) => {

    const [isPending, startTransition] = React.useTransition();
    const [optionsList, setOptionsList] = React.useState([]);

    React.useEffect(() => {
        // startTransition(() => {
        //     setOptionsList(options);
        // });
        setOptionsList(options);
    }, [options]);

    const [errorS, setErrorS] = React.useState(false);
    const [helperText, setHelperText] = React.useState('תוכן לא חוקי');
    React.useEffect(() => {
        setErrorS(error);
    }, [error]);

    const body = <FormControl
        variant="outlined"
        // size='small'
        fullWidth
        error={errorS}
        disabled={disabled}
    // sx={{ bgcolor: "primary.lighter" }}
    >
        <InputLabel shrink>{label}</InputLabel>
        <Select
            label={label}
            required={required}
            name={name}
            error={errorS}
            disabled={disabled}
            value={inputs[name] ? inputs[name] : '0'}
            onChange={handleChange}
            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
            onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
            MenuProps={{sx: {maxHeight: 300}}}
            onBlur={(e) => {

                if (validate) validate({
                    value: e.target.value,
                    inputs,
                    setError: setErrorS,
                    setMessage: setHelperText
                })

                if (onBlur) onBlur({
                    value: e.target.value,
                    name: e.target.name,
                    inputs,
                    setError: setErrorS,
                    setMessage: setHelperText
                })
            }}
        >

            {first && <MenuItem value="0">בחירת {label}</MenuItem>}
            {/* {(isPending) && <Box>
                <LinearProgress />
            </Box>} */}
            {/* {(!isPending) && optionsList.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))} */}
            {optionsList.map(option => (
                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
        </Select>
        {errorS && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>

    return (

        <>
            {tooltip && <Tooltip title={tooltip} arrow>{body}</Tooltip>}
            {!tooltip && body}
        </>
    )
}

// defaultProps 
GenericSelect.defaultProps = {
    name: 'name',
    label: 'label',
    inputs: null,
    required: false,
    error: false,
    disabled: false,
    validate: null,
    onBlur: null,
    tooltip: null
}

export default GenericSelect