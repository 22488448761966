import React from "react";
import { useQuery } from "react-query";

import http from "../../../api/http";

import data from "./data.json";

function useLookup() {

    // const { data, isLoading, isError } = useQuery(
    //     ['lookup'],
    //     async () => await http.get("Lookup"),
    // );

    // console.log(data);

    // if (isError) {
    //     console.log("Lookup Error", isError);
    // }

    const getLookup = (lookupName, parentID = null) => {
        let arr = [];
        data.map(lookup => {
            if (lookup.tableCode === lookupName) {
                if (parentID) {
                    if (lookup.parentID == parentID) {
                        arr.push({ value: lookup.code, label: lookup.name });
                    }
                }else{
                    arr.push({ value: lookup.code, label: lookup.name });
                }   
            }
        });
        return arr;
    }

    const getObj = (lookupName, code) => {
        let obj = {};
        data.map(lookup => {
            if (lookup.tableCode === lookupName) {
                if (lookup.code == code) {
                    obj = { ...lookup };
                }
            }
        });
        return obj;
    }

    // return { getLookup, isLoading };
    return { getLookup, getObj };
}
export default useLookup;