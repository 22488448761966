import React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from 'react-query';

import { Grid, Box, Button, Typography, Paper, Divider, ButtonGroup, Badge } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

import http from '../../../api/http';

import {
  WazeIcon,
  NoteIcon,
  PhoneIcon,
  PlaceIcon,
  CheckIcon
} from '../../../assets/icons';
import GenericInput from '../../../components/GenericForm/GenericInput';
import { Stack } from '@mui/system';
import SearchForm from '../../../components/SearchForm';

const CourierItem = () => {

  let navigate = useNavigate();
  const { deliveryId } = useParams();
  const queryClient = useQueryClient();
  const [search, setSearch] = React.useState('');
  const [dataArr, setDataArr] = React.useState([]);

  const { data, isLoading, isError, isSuccess } = useQuery(['deliveryForCourier'], () => http.get(`deliveries/courier/${deliveryId}`));

  console.log("data:", data);


  const isCollect = () => {
    if (!data) return false;
    if (!data.list) return false;
    let arr = data.list.filter(d => d.status == 5);
    if (arr.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const disabledBtn = (id) => {
    if (deliveryId != id) return false;
    if (data.list.length > 1) {
      return true;
    }
    else {
      return false;
    }
  }

  const getStatus = (delivery) => {

    let status = 0;

    if (delivery.status == 2) status = 3;
    if (delivery.status == 3 && delivery.collect == 0) status = 4;
    if (delivery.status == 3 && delivery.collect > 0) status = 5;
    if (delivery.status == 5) status = 4;

    return status;

  }

  const updateDeliveryStatus = useMutation(obj => http.put(`deliveries/status/${obj.id}/${obj.status}`), {
    onSuccess: () => {
      queryClient.refetchQueries(['deliveryForCourier'], { active: true, exact: true });
      if (data && (!data.list || data.list.length == 1)) {
        navigate('/courier');
      }
    }
  });
  React.useEffect(() => {
    if (data) {
      setDataArr(data.list);
    }
  }, [data]);

  return (
    <Box sx={{ width: { xs: 1, sm: 500 }, m: 'auto' }}>
      <ButtonGroup size="large" fullWidth variant="contained">
        <Button onClick={() => navigate('/courier')}><LogoutIcon /></Button>
        <Button disabled={!data} sx={{ bgcolor: '#24782C' }} onClick={() => {
          let a = document.createElement("a");
          a.href = `tel:${data.phone}`;
          a.click();
          a.remove();
        }}><PhoneIcon /></Button>
        <Button disabled={!data} sx={{ bgcolor: '#01B3FF' }} onClick={() => {
          let a = document.createElement("a");
          a.href = `https://waze.com/ul?q=${data.waze}&z=10&navigate=yes`;
          a.target = '_blank';
          a.click();
          a.remove();
        }}><WazeIcon /></Button>
      </ButtonGroup>
      {data &&
        <Box sx={{ mt: 1, height: '85vh', overflowY: 'auto' }}>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <Typography align='center' variant='h5' color='primary'>
                {data.status == 3 ? 'מסירה' : 'איסוף'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={3} direction="row" justifyContent="center" alignItems="center">
                {data.status == 2 && <SearchForm
                  value={search}
                  placeholder="חיפוש משלוח"
                  onClick={(search) => {
                    setSearch(search);
                    if (search) {
                      let arr = data.list.filter(d => d.id.toString().includes(search));
                      setDataArr(arr);
                    } else {
                      setDataArr(data.list);
                    }
                  }}

                />
                }
                <Typography align='center' variant='h5' color='primary'>
                  {(data.collect > 0 || isCollect()) && <span style={{ color: 'red' }}>&nbsp;+&nbsp;גוביינא</span>}
                  {(data.status == 2 || data.status == 5) && <>
                    <br />
                    <Badge sx={{ mx: 2, mt: 0.5 }} badgeContent={data.list.length} color="primary" />
                    <span style={{ fontSize: '50%' }}>&nbsp;משלוחים</span>
                  </>}
                </Typography>
              </Stack>
            </Grid>
            {data.status == 3 && <>
              <Grid item xs={6}>
                <Typography sx={{ lineHeight: 1 }} align='center' variant='h6' color='primary'>
                  <span style={{ fontSize: '50%' }}>מס׳ משלוח:</span><br />
                  {deliveryId}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ lineHeight: 1 }} align='center' variant='h6' color='primary'>
                  <span style={{ fontSize: '50%' }}>מאת:</span><br />
                  {data.f_name}
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
              <Grid item xs={6}>
                <Typography sx={{ lineHeight: 0.8, mt: -0.5 }} align='center' variant='h6' color='primary'>
                  <Badge badgeContent={data.packeges} color="primary" /><br />
                  <span style={{ fontSize: '50%' }}>חבילות</span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {data.collect > 0 && <Typography sx={{ lineHeight: 0.8 }} align='center' variant='h6' color='red'>
                  {data.collect} ₪<br />
                  <span style={{ fontSize: '50%' }}>גוביינא</span>
                </Typography>}
              </Grid>
            </>}
            {(data.status == 2 || data.status == 5) && <Grid item xs={12} sx={{ p: 1 }}>
              {dataArr && dataArr.map(d => <Paper key={d.id} elevation={2} sx={{ mb: 0.5 }}>
                <Grid container>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                    <Typography variant='p' color='primary' sx={{ pl: 2 }}>
                      <strong>{d.id}</strong>
                      {" - "}
                      <span style={{ fontSize: "80%" }}>{d.city}</span>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {d.status == 2 && <Typography variant='p' color='primary'>
                      <Badge badgeContent={d.packeges} color="primary" />
                      <span style={{ fontSize: '50%', marginRight: '18px', marginBottom: '150px' }}>חבילות</span>
                    </Typography>}
                    {d.status == 5 && <Typography variant='p' color='red'>
                      {d.collect} ₪
                    </Typography>}
                  </Grid>
                  <Grid item xs={4} sx={{ p: 1 }}>
                    <Button variant='contained' color={d.status == 2 ? 'primary' : 'error'} fullWidth
                      disabled={disabledBtn(d.id) || updateDeliveryStatus.isLoading}
                      onClick={() => { updateDeliveryStatus.mutate({ id: d.id, status: getStatus(d) }) }}>
                      {d.status == 2 ? 'אספתי' : 'מסרתי'}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>)}
            </Grid>}
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <PlaceIcon />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ lineHeight: 0.8 }} align='center' variant='p' color='primary'>
                    <span style={{ fontSize: '75%' }}>אזור {data.area}</span><br />
                    {data.address}
                    <br />
                    <span style={{ fontSize: '50%' }}>קומה:</span>
                    {data.floor}
                    &nbsp;
                    <span style={{ fontSize: '50%' }}>דירה</span>
                    {data.appartment}
                    {/* &nbsp;
                                        <span style={{ fontSize: '50%' }}>כניסה</span>
                                        א
                                        <br /> */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <ContactPhoneIcon color='primary' />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ lineHeight: 0.8 }} align='center' variant='p' color='primary'>
                    <span style={{ fontSize: '75%' }}>{data.contact}</span><br />
                    {data.phone}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}><Divider /></Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <NoteIcon />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ lineHeight: 0.8 }} align='center' variant='cption' color='primary'>
                    {data.note}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {data.status == 3 && <>
              <Grid item xs={12}></Grid>
              <Grid item xs={12} sx={{ p: 1, display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" endIcon={<CheckIcon />} fullWidth
                  color={data.collect > 0 ? 'error' : 'primary'}
                  disabled={updateDeliveryStatus.isLoading}
                  onClick={() => {
                    updateDeliveryStatus.mutate({ id: deliveryId, status: getStatus(data) })
                  }}>
                  מסרתי
                  {data.collect > 0 && <span>&nbsp;וקיבלתי גוביינא</span>}
                </Button>
              </Grid>
            </>}
          </Grid>
        </Box>
      }
    </Box>
  )
}

export default CourierItem;