import React from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import { Grid, Stack, Button, ToggleButtonGroup, ToggleButton, IconButton, Card } from '@mui/material';

import http from '../../../api/http';
import Context from '../../../context';

import GenericForm from '../../../components/GenericForm';

const AddNewCustomer = ({ }) => {

    const queryClient = useQueryClient();

    const { snackbar, closePopup } = React.useContext(Context);

    const addCustomer = useMutation((obj) => http.post(`customers/`, obj), {
        onSuccess: (data) => {
            queryClient.refetchQueries(['getCustomersForManager'], { active: true, exact: true });
            snackbar('לקוח נוסף בהצלחה ', 'success');
            closePopup();
        },
        onError: (error) => {
            snackbar("אירעה שגיאה בהוספת לקוח", 'error');
        }
    });


    return (
        <Grid container >
            <Grid item xs={12}>
                <GenericForm
                    fields={[
                        { label: "שם משתמש", name: "username", type: "text", size: {xs:12,md:6, lg:4}, required: true },
                        { label: "שם לקוח", name: "name", type: "text", size: {xs:12,md:6, lg:4}, required: true },
                        { label: " טלפון", name: "phone", type: "text", size: {xs:12,md:6, lg:4}, required: true },
                        { label: "מייל", name: "email", type: "text", size: {xs:12,md:6, lg:4}, required: true },

                        { label: "שמירת לקוח", type: 'submit', variant: 'contained' },
                       
                    ]}
                 
                    onSubmit={(inputs) => {
                        addCustomer.mutate(inputs);
                    }}
                    
                />
            </Grid>

        </Grid>
    )
}

export default AddNewCustomer;

