import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'

import './App.css';
import ProjectRoutes from './routes';
import ContextProvider from './context/ContextProvider';
import ThemeCustomization from './themes';

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ThemeCustomization>
        <ContextProvider>
          <ProjectRoutes />
        </ContextProvider>
      </ThemeCustomization>
    </QueryClientProvider>
  );
}

export default App;
