//generate a random number between min and max
export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatDateJS(_date = null) {

    const date = _date ? new Date(_date) : new Date();
    // if (date.getTime() < 0) {
    //     return null;
    // }

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');

}

//format a date
export function formatDate(_date = null) {

    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');

}

export function formatMonth(_date = null) {
    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;

    return [month, year].join('/');
}

export function formatMonthJS(_date = null) {
    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let year = date.getFullYear();

    if (month.length < 2) month = '0' + month;

    return [year, month].join('-');
}

export function formatDateTime(_date = null) {

    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    let hour = '' + date.getHours();
    let minute = '' + date.getMinutes();
    let second = '' + date.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return [day, month, year].join('/') + ' ' + [hour, minute, second].join(':');
}

export function formatDateTimeObj(_date = null) {

    const date = _date ? new Date(_date) : new Date();

    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();

    let hour = '' + date.getHours();
    let minute = '' + date.getMinutes();
    let second = '' + date.getSeconds();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    if (hour.length < 2) hour = '0' + hour;
    if (minute.length < 2) minute = '0' + minute;
    if (second.length < 2) second = '0' + second;

    return {
        date: [day, month, year].join('/'),
        time: [hour, minute].join(':')
    };
}

export function formatCurrency(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatCurrencyIL(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "\xa0₪";
}
export function formatMinusCurrencyIL(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    value = parseFloat(value).toFixed(2);
    // add minus sign before the number
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "-" + "\xa0₪";
}
export function formatPercent(value) {
    if (value === null || value === undefined || value === '' || isNaN(value)) value = 0;
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "%";
}

// paras object to query string
export function queryString(obj, first = '?') {
    var str = [];
    for (var p in obj)
        if (obj[p] !== undefined && obj[p] !== null && obj[p] !== '' && obj.hasOwnProperty(p)) {
            if(typeof obj[p] === 'boolean') obj[p] = obj[p] ? 1 : 0;
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.length > 0 ? first + str.join("&") : "";
}

export function formatReadMore(text) {
    if (text === null || text === undefined || text === '') return '';
    if (text.length > 5) {
        return text.substring(0, 5) + '\xa0...';
    }
    return text;
}

// convert string to number
export function convertToNumber(value) {
    return parseFloat(value.replace(/,/g, ''));
}

// if diff is max n return true
export function isDiffN(value1, value2 , n = 1) {
    // console.log(value1, value2);
    return Math.abs(value1 - value2) <= n;
}
