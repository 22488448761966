import React from 'react';
import { useQuery } from 'react-query';

import { Card, FormGroup, FormControlLabel, Switch, Typography, Divider, Autocomplete, Box, TextField, Stack } from '@mui/material';

import http from '../../../api/http';
import { formatDateJS } from '../../../utils/func';
import GenericForm from '../../../components/GenericForm';
import SearchLocationInput from '../../../global/SearchLocationInput';

const headerText = {
    from: 'מוצא',
    to: 'יעד'
}

const Location = ({ type, location, setLocation, errors, setErrors }) => {

    const locations = useQuery(['locations'], () => http.get('locations'));
    const locationID = useQuery(['customerLocationID'], () => http.get('locations/customer'));

    const [isNew, setIsNew] = React.useState(false);

    React.useEffect(() => {
        setIsNew(type === "from" ? false : true);
    }, [type])

    React.useEffect(() => {

        if (isNew) return;
        if (type !== 'from') return;
        if (!locations.isSuccess) return;
        if (!locationID.isSuccess) return;

        let l = locations.data.find(l => l.id === locationID.data.location_id);
        setLocation(l);
        setIsNew(false);
        
    }, [locations.data])

    const fields = [
        { type: "line", label: "איתור כתובת" },
        {
            cb: () => <FormGroup sx={{mb: -1.5}}>
                <FormControlLabel
                    control={<Switch
                        size='small'
                        checked={!isNew}
                        onChange={(e) => {
                            setIsNew(!isNew);
                            setLocation({ id: 0 });
                        }}
                    />}
                    label={<Typography variant='body1'>מקומות קודמים</Typography>} />
            </FormGroup>
        },
        {
            cb: () => isNew
                ? <SearchLocationInput id={type}
                    onSelect={(data) => {
                        setLocation(values => ({
                            ...values,
                            id: 0,
                            ...data
                        }));
                    }} />
                : <Autocomplete
                    size='small'
                    disablePortal
                    disableClearable
                    id={`saved_${type}`}
                    options={locations.isSuccess ? locations.data : []}
                    autoHighlight
                    getOptionLabel={(option) => `${option.id} - ${option.name}`}
                    renderOption={(props, option) => (
                        <Box key={option.id} component="li" {...props}>
                            <Typography variant='p' sx={{ width: 1 }}>
                                {option.name}
                                <br />
                                <span style={{ fontSize: "70%" }}>{option.street} {option.num} {option.city}</span>
                                <Divider />
                            </Typography>
                        </Box>
                    )}
                    onChange={(event, newValue) => {
                        setLocation(newValue)
                    }}
                    renderInput={(params) => <TextField {...params} placeholder="מיקומים שמורים" />}
                />
        },
        { type: "line", label: "כתובת" },
        { name: "name", label: "שם מקום", type: "text", error: errors?.name ? true : false, required: true, disabled: !isNew, onBlur: () => {
            if (!location.contact || location.contact == "") setLocation({ ...location, contact: location.name });
        } },
        { name: "area", label: "אזור", type: "text", size: 6, disabled: !isNew },
        { name: "city", label: "עיר", type: "text", error: errors?.city ? true : false, size: 6, required: true, disabled: !isNew },
        { name: "street", label: "רחוב", type: "text", error: errors?.street ? true : false, required: true, disabled: !isNew },
        { name: "num", label: "מספר בית", type: "text", error: errors?.num ? true : false, required: true,  size: {xs:6,md:4, lg:3}, disabled: !isNew },
        { name: "floor", label: "קומה", type: "text", size: {xs:6,md:4, lg:3}, disabled: !isNew },
        { name: "appartment", label: "דירה", type: "text", size: {xs:6,md:4, lg:3}, disabled: !isNew },
        { name: "entrance", label: "כניסה", type: "text", size: {xs:6,md:4, lg:3}, disabled: !isNew },
        { name: "note", label: "הערה לכתובת", type: "textarea", disabled: !isNew },
        { type: "line", label: "פרטי איש קשר" },
        { name: "contact", label: "שם", type: "text", error: errors?.contact ? true : false, required: true, size: 4, disabled: !isNew },
        { name: "phone", label: "טלפון", type: "text", error: errors?.phone ? true : false, errorText: "מספר ישראלי בלבד", required: true, size: 4, disabled: !isNew },
        { name: "phone2", label: "טלפון נוסף", type: "text", size: 4, disabled: !isNew },
    ];

    return (
        <Card elevation={0} sx={{ p: 2 }}>
            <Typography variant="h4" component="h2" color="primary" sx={{ mb: 2 }}>
                פרטי {headerText[type]}
            </Typography>
            <GenericForm
                fields={fields}
                initInputs={location}
                setInitInput={setLocation}
            />
        </Card>
    )
}

export default Location;