import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Grid, Stack, Button, ToggleButtonGroup, ToggleButton, IconButton, Card } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';

import http from '../../../api/http';
import Context from '../../../context';
import { formatDateJS } from '../../../utils/func';
import GenericForm from '../../../components/GenericForm';
import PrintDialog from './PrintDialog';

const RowCollaapse = ({ row }) => {

    // console.log(row);

    const queryClient = useQueryClient();
    const { snackbar, confirm, popup } = React.useContext(Context);

    const [edit, setEdit] = React.useState(false);
    const [inputsState, setInputsState] = React.useState({
        d_date: formatDateJS(row.d_date),
        packeges: row.packeges,
        collect: row.collect,
        type: row.type
    });

    const updateDelivery = useMutation((obj) => http.put("deliveries/" + row.id, obj), {
        onSuccess: () => {
            queryClient.refetchQueries(['deliveriesForCustomer'], { active: true, exact: true });
            snackbar("המשלוח עודכן בהצלחה", "success");
        },
        onError: () => {
            snackbar("אירעה שגיאה בעדכון המשלוח", "error");
        }
    });

    const cancelDelivery = useMutation((obj) => http.put("deliveries/cancel/" + row.id), {
        onSuccess: () => {
            queryClient.refetchQueries(['deliveriesForCustomer'], { active: true, exact: true });
            snackbar("המשלוח בוטל בהצלחה", "success");
        },
        onError: () => {
            snackbar("אירעה שגיאה בביטול המשלוח", "error");
        }
    });

    const fields = [
        { name: 'd_date', label: 'תאריך איסוף', type: 'date', size: {xs:12,md:6, lg:3}, inputProps: { min: formatDateJS(row.creation)} },
        { name: 'packeges', type: 'number', label: 'מספר חבילות', size: {xs:12,md:6, lg:3} },
        { name: 'collect', type: 'currencyIL', label: 'גוביינא', size: {xs:12,md:6, lg:3} },
        {
            size: {xs:12,md:4, lg:3},
            cb: () => <ToggleButtonGroup
                color="primary"
                name="type"
                value={inputsState.type.toString()}
                exclusive
                fullWidth
                onChange={(e, v) => setInputsState({ ...inputsState, type: v })}
            >
                <ToggleButton sx={{ p: 1 }} name='type' value='1'>רגיל</ToggleButton>
                <ToggleButton sx={{ p: 1 }} name='type' value='2'>אקספרס</ToggleButton>
                <ToggleButton sx={{ p: 1 }} name='type' value='3'>כפול</ToggleButton>
            </ToggleButtonGroup>
        }
    ];

    return (
        <Grid container spacing={2}>
            {edit && <Grid item xs={0.5}>
                <Stack direction='row' justifyContent="center" alignItems="center" spacing={2} sx={{ height: 1 }}>
                    <IconButton size='large' onClick={() => setEdit(false)}>
                        <CloseIcon color='error' fontSize='large' />
                    </IconButton>
                </Stack>
            </Grid>}
            {edit && <Grid item xs={11.5}>
                <Card elevation={0} sx={{ p: 2 }}>
                    <GenericForm
                        fields={fields}
                        initInputs={inputsState}
                        setInitInput={setInputsState}
                    />
                </Card>
            </Grid>}
            <Grid item xs={12}>
                <Stack direction='row' justifyContent="end" spacing={2}>
                    {edit && <Button disabled={updateDelivery.isLoading} variant="contained" color="primary" startIcon={<SaveIcon />} onClick={() => {
                        let obj = inputsState;
                        obj.d_date = formatDateJS(obj.d_date);
                        updateDelivery.mutate(obj);
                    }}>שמירה</Button>}
                    <Button disabled={edit || updateDelivery.isLoading} variant="contained" color="info" startIcon={<EditIcon />} onClick={() => setEdit(true)}>עריכה</Button>
                    <Button disabled={updateDelivery.isLoading} variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => {
                        confirm({
                            message: "האם אתה בטוח שברצונך לבטל את המשלוח?",
                            onConfirm: () => {
                                cancelDelivery.mutate();
                            }
                        })
                    }}>ביטול</Button>
                    <Button disabled={updateDelivery.isLoading} variant="contained" color="success" startIcon={<PrintIcon />} onClick={() => {
                        popup({
                            title: "הדפסת משלוח",
                            content: <PrintDialog array={[row.id]} printed={true} />,
                            onClose: () => { 
                                queryClient.refetchQueries(['deliveriesForCustomer'], { active: true, exact: true });
                            }
                        })
                    }}>הדפסה</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default RowCollaapse