import axios from 'axios'

const BASE_URL = 'https://api.todoor.me/'
// const BASE_URL = 'http://localhost:3005/'

export default {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    }
}

async function ajax(endpoint, method = 'GET', data = null) {
    // console.log(BASE_URL + endpoint);
    // const params = (method === 'GET') ? data : {};
    // if(localStorage.getItem('uc')) params.uc = localStorage.getItem('uc');

    let params = null;
    if (method === 'GET') {
        params = data ? data : {};
        if(localStorage.getItem('uc')) params.uc = localStorage.getItem('uc');
    }else if (localStorage.getItem('uc')){
        params = {uc: localStorage.getItem('uc')};
    }else{
        params = null;
    }

    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            headers: {
                'x-todoor-api-key': getToken()
            },
            params: params
        });
        return res.data.data;
    } catch (err) {
        // console.log(`Had Issues ${method}ing to the backend, endpoint: ${endpoint}, with data: ${data}`);
        console.dir(err);
        console.log(err.response.data);
        throw err.response.data;
    }
}

function getToken() {
    const token = localStorage.getItem('token');
    return token ? token : '';
}
function getQuery() {
    const uc = localStorage.getItem('uc');
    return uc ? `?uc=${uc}` : '';
}

export async function downloadPDF(endpoint, filename, method = 'GET', data = null) {
    try {
        const f = await fetch(`${BASE_URL}${endpoint}${getQuery()}`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-todoor-api-key': getToken()
            },
            method: method,
            body: method === 'GET' ? null : JSON.stringify(data)
        });
        if(!f.ok) return false;
        const blob = await f.blob();
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.setAttribute("download", filename + ".pdf");
        a.click();
        a.remove();   
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
}