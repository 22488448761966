import React from 'react';

import { Grid, Stack, Button } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DeleteIcon from '@mui/icons-material/Delete';

import { useQueryClient } from 'react-query';
import { useMutation } from 'react-query';

import http from '../../../api/http';
import Context from '../../../context';

const RowCollaapse = ({ row }) => {

     console.log("row:",row);

    const queryClient = useQueryClient();
    const { snackbar, confirm } = React.useContext(Context);

    const setLocation = useMutation((obj) => http.put("locations/customer/" + row.id), {
        onSuccess: () => {
            queryClient.refetchQueries(['customerLocationID'], { active: true, exact: true });
            snackbar("המיקום הוגדר כברירת מחדל", "success");
        },
        onError: () => {
            snackbar("אירעה שגיאה בעידכון מיקום", "error");
        }
    });

    // const cancelLocation = useMutation((obj) => http.put("locations/cancel/" + row.id), {
    //     onSuccess: () => {
    //         // queryClient.refetchQueries(['deliveriesForCustomer'], { active: true, exact: true });
    //         snackbar("המיקום בוטל בהצלחה", "success");
    //     },
    //     onError: () => {
    //         snackbar("אירעה שגיאה בביטול המיקום", "error");
    //     }
    // });


    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction='row' justifyContent="end" spacing={2}>

                    <Button variant="contained" color="info" startIcon={<LocationOnIcon />} onClick={() => {
                        setLocation.mutate();
                    }}>הגדר כמיקום ברירת מחדל</Button>

                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => {
                        confirm({
                            message: "האם אתה בטוח שברצונך לבטל את המיקום?",
                            onConfirm: () => {
                            //  cancelDelivery.mutate();
                            }
                        })
                    }}>מחיקה</Button>

                </Stack>
            </Grid>
        </Grid>
    )
}

export default RowCollaapse