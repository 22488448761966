import React from "react";
import { Routes, Route } from "react-router-dom";

import Context from "../context";

import Layout from '../layout/Layout';
import NoLayout from '../layout/NoLayout';

import Deliveries from '../pages/Customer/Deliveries';
import Delivery from '../pages/Customer/Delivery';
import DeliveryForm from '../pages/Customer/DeliveryForm';
import DeliveriesApi from '../pages/Customer/DeliveriesApi';
import Courier from '../pages/Courier/Courier';
import CourierItem from '../pages/Courier/CourierItem';
import Customers from '../pages/Company/Customers';
import Locations from '../pages/Customer/Locations';
import Auth from '../pages/Global/Auth';
import TrackingDelivery from "../pages/Global/TrackingDelivery";
import DeliveriesCustomers from "../pages/Company/DeliveriesCustomers";

const pages = {
    customerDeliveries: {permissions:['customer', 'manager'], path: '/customer/deliveries'},
    customerDeliveryForm: {permissions:['customer', 'manager'], path: '/customer/delivery-form/:deliveryId'},
    customerDelivery: {permissions:['customer', 'manager'], path: '/customer/delivery/:deliveryId'},
    customerDeliveriesApi: {permissions:['customer'], path: '/customer/deliveries-api'},
    courier: {permissions:['courier', 'manager'], path: '/courier'},
    courierDelivery: {permissions:['courier', 'manager'], path: '/courier/delivery/:deliveryId'},
    companyCustomers: {permissions:['manager'], path: '/company/customers'},
    customerLocations: {permissions:['customer'], path: '/customer/locations'},
    companyDeliveriesCustomers: {permissions:['manager'], path: '/company/deliveries'}
}


const ProjectRoutes = () => {

    const { user } = React.useContext(Context);

    const checkPermissions = React.useCallback((page) => {  
        if (!user) return false;
        if (pages[page].permissions.includes(user.role)) {
            return true
        }
        return false
    }, [user]);

    return (
        <Routes>

            <Route path="/" element={<NoLayout />}>

                <Route index element={<Auth />} />
                <Route path="tracking-delivery/" element={<TrackingDelivery />} />
                <Route path="tracking-delivery/:id" element={<TrackingDelivery />} />

                <Route path="customer" element={checkPermissions("customerDeliveries") ? <Layout /> : <NoLayout />}>
                    <Route index element={checkPermissions("customerDeliveries") ? <Deliveries /> : <Auth />} />
                    <Route path="delivery-form/:deliveryId" element={checkPermissions("customerDeliveryForm") ? <DeliveryForm /> : <Auth />} />
                    {/* <Route path="delivery/:deliveryId" element={checkPermissions("customerDelivery") ? <Delivery /> : <Auth />} />
                    <Route path="deliveries" element={checkPermissions("customerDeliveries") ? <Deliveries /> : <Auth />} /> */}
                    <Route path="deliveries-api" element={checkPermissions("customerDeliveriesApi") ? <DeliveriesApi /> : <Auth />} />
                    <Route path="locations" element={checkPermissions("customerLocations") ? <Locations /> : <Auth />} />
                </Route>

                <Route path="courier" element={<NoLayout />}>
                    <Route index element={checkPermissions("courier") ? <Courier /> : <Auth />} />
                    <Route path="delivery/:deliveryId" element={checkPermissions("courierDelivery") ? <CourierItem /> : <Auth />} />
                </Route>

                <Route path="company" element={checkPermissions("companyCustomers") ? <Layout /> : <NoLayout />}>
                    <Route index element={checkPermissions("companyCustomers") ? <Customers /> : <Auth />} />
                    <Route path="/company/customers" element={checkPermissions("companyCustomers") ? <Customers /> : <Auth />} />
                    <Route path="/company/deliveries" element={checkPermissions("companyDeliveriesCustomers") ? <DeliveriesCustomers/> : <Auth />} />
                </Route>

            </Route>

        </Routes>
    )
}

export default ProjectRoutes;