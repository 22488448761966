import React from 'react';

import { Card, ToggleButtonGroup, ToggleButton, Typography, Divider } from '@mui/material';

import { formatDateJS } from '../../../utils/func';
import GenericForm from '../../../components/GenericForm';

const FormHeader = ({ delivery, setDelivery }) => {

    const fields = [
        { name: 'd_date', label: 'תאריך איסוף', type: 'date', size: 3, inputProps: { min: formatDateJS() } },
        { name: 'packeges', type: 'number', label: 'מספר חבילות', size: 3 },
        { name: 'collect', type: 'currencyIL', label: 'גוביינא', size: 3 },
        {
            size: 3,
            cb: () => <ToggleButtonGroup
                color="primary"
                name="type"
                value={delivery.type ? delivery.type.toString() : '1'}
                exclusive
                fullWidth
                onChange={(e, v) => setDelivery({ ...delivery, type: v })}
            >
                <ToggleButton sx={{ p: 1 }} name='type' value='1'>רגיל</ToggleButton>
                <ToggleButton sx={{ p: 1 }} name='type' value='2'>אקספרס</ToggleButton>
                <ToggleButton sx={{ p: 1 }} name='type' value='3'>כפול</ToggleButton>
            </ToggleButtonGroup>
        }
    ];
    return (
        <Card elevation={0} sx={{ p: 2 }}>
            <Typography variant="h4" component="h2" color="primary" sx={{mb:2}}>
                פרטים כלליים
            </Typography>
            {/* <Divider sx={{mb:2}} /> */}
            <GenericForm
                fields={fields}
                initInputs={delivery}
                setInitInput={setDelivery}
            />
        </Card>
    )
}

export default FormHeader;