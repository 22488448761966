import React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Checkbox, Card, Grid, Typography, IconButton, Stack, Tooltip, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';

import http from '../../../api/http';
import Context from '../../../context';
import GenericTable from '../../../components/GenericTable';
import GenericForm from '../../../components/GenericForm';
import RowCollaapse from './RowCollaapse';
import { formatDateTime, formatDate, formatCurrencyIL } from '../../../utils/func';
import AddNewCustomer from './AddNewCustomer';

const Customers = () => {

  const getCustomersForManager = useQuery(['getCustomersForManager'], () => http.get('customers/get-for-manager'));

  const queryClient = useQueryClient();
  const { popup } = React.useContext(Context);

  const [searchInpiuts, setSearchInputs] = React.useState({});
  const [dataToShow, setDataToShow] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);


  React.useEffect(() => {
    if (getCustomersForManager.data) {

      const dataFromServer = getCustomersForManager.data[0];

      // const filteredData = dataFromServer.filter((item) => {
      //   let isFound = true;
      //   Object.keys(searchInpiuts).forEach((key) => {

      //     if (searchInpiuts[key] == '0' || searchInpiuts[key] == '' || searchInpiuts[key] == null || searchInpiuts[key] == undefined) {
      //       return;
      //     }

      //     switch (key) {
      //       case 'id':
      //         if (item[key] != searchInpiuts[key]) isFound = false;
      //         break;
      //       case 'status':
      //         if (item[key] != searchInpiuts[key]) isFound = false;
      //         break;
      //       case 'dateStart':
      //         let dateStart = new Date(searchInpiuts[key]);
      //         dateStart.setDate(dateStart.getDate() - 1);
      //         if (new Date(item["date"]) < dateStart) isFound = false;
      //         break;
      //       case 'dateEnd':
      //         if (new Date(item["date"]) > new Date(searchInpiuts[key])) isFound = false;
      //         break;
      //       default:
      //         isFound = true;
      //     }

      //   });
      //   return isFound;
      // });

      // setCount(filteredData.length);
      setCount(dataFromServer.length);
      setDataToShow(dataFromServer.slice(page * 50, page * 50 + 50));

    } else {
      setDataToShow([]);
    }
  }, [getCustomersForManager.status, getCustomersForManager.isFetching, page, searchInpiuts]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Card elevation={0} sx={{ borderRadius: 1, p: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={10}>
              <GenericForm
                fields={[
                  { label: "שם לקוח", name: "id", type: "text", size: 2.5, disabled: true },
                  { label: "חיפוש", type: "submit", size: 2, variant: "contained", disabled: true },
                ]}
                onSubmit={(values) => setSearchInputs(values)}
              />
            </Grid>
            <Grid item xs={2}>
              <Stack direction="row" spacing={1} justifyContent="end" alignItems="center" sx={{ width: 1, height: 1 }}>
                <Tooltip title="הוספת לקוח">
                  <IconButton
                    size="large"
                    onClick={() => {
                      popup({
                        title: "הוספת לקוח חדש",
                        content: <AddNewCustomer />,
                      })
                    }}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {count == 0 && <Typography variant="h4" align="center" color="error" sx={{ my: 3 }}>לא נמצאו לקוחות</Typography>}
        {count > 0 && <Card elevation={0} sx={{ pt: 0, pb: 7, px: 2, borderRadius: 1, height: "85vh" }}>
          <GenericTable
            columns={[
              { label: "שם חברה", key: "customers_name", type: "text" },
              { label: "שם לקוח", key: "users_name", type: "text" },
              { label: "שם משתמש", key: "username", type: "text" },
              { label: "טלפון", key: "phone", type: "copy" },
              { label: "מחיר משלוח", key: "price", type: "currencyIL" },
              { label: "חבילה נוספת", key: "packege", type: "currencyIL" },
              { label: "גוביינא", key: "collect", type: "currencyIL" },
              { label: "משלוח אקספרס", key: "express", type: "currencyIL" },
              { label: "משלוח כפול", key: "d_double", type: "currencyIL" },

              {
                collapse: ({ row, openCollapse }) => {
                  return openCollapse ? <RowCollaapse row={row} /> : null;
                },
                cb: ({ row, openCollapse, setOpenCollapse }) => <IconButton
                  color="primary"
                  onClick={() => {
                    setOpenCollapse(open => !open);
                  }}
                >
                  {!openCollapse ? <ExpandMoreIcon fontSize='medium' /> : <ExpandLessIcon fontSize='medium' />}
                </IconButton>
              }
            ]}
            data={dataToShow}
            title="לקוחות"
            counter={count}
            pagination
            page={page}
            setPage={setPage}
          />
        </Card>}
      </Grid>
    </Grid>
  )
}

export default Customers;