import React from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, MenuList, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';

import Context from '../../../../context';

// ==============================|| DRAWER CONTENT ||============================== //

const dashboardIcon = <DashboardRoundedIcon fontSize='large' />;
const listItemsTop = [
    {
        listIcon: dashboardIcon,
        listText: "משלוח חדש",
        navigateTo: "/customer/delivery-form/new",
        key: '1',
        permissions: ['customer', 'manager']
    },
    {
        listIcon: dashboardIcon,
        listText: "משלוחים",
        navigateTo: "/customer",
        // navigateTo: "/customer/deliveries",
        key: '2',
        permissions: ['customer']
    },
    {
        listIcon: dashboardIcon,
        listText: "משלוחים",
        navigateTo: "/company/deliveries",
        key: '3',
        permissions: ['manager']
    },
    {
        listIcon: dashboardIcon,
        listText: "שליח",
        navigateTo: "/courier",
        key: '4',
        permissions: ['courier', 'manager']
    },
    {
        listIcon: dashboardIcon,
        listText: "אישורים",
        navigateTo: "/customer/deliveries-api",
        key: '5',
        permissions: ['customer']
    },
    {
        listIcon: dashboardIcon,
        listText: "לקוחות",
        navigateTo: "/company/customers",
        key: '6',
        permissions: ['manager']
    },
    {
        listIcon: dashboardIcon,
        listText: "מיקומים",
        navigateTo: "/customer/locations",
        key: '7',
        permissions: ['customer']
    },
    
];

const DrawerContent = ({ setOpen }) => {

    const navigate = useNavigate();
    const theme = useTheme();

    const { user } = React.useContext(Context);

    const [selected, setSelected] = React.useState(null);

    const style = {
        ListStyle: {
            width: '85px',
            borderRadius: '18px',
            boxShadow: theme.customShadows.z1,
            mb: 2,
            padding: 0.3,
            cursor: "pointer",
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.default,
            },
        },
        center: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            alignContent: 'center',
            textAlign: "center"
        }
    };

    return (
        <Box sx={{ width: 1 }}>
            <MenuList sx={{ minWidth: { xs: "50vw", md: 0 } }}>
                <List sx={{ ...style.center }}>
                    {listItemsTop.map((listItem) => {

                        if (!listItem.permissions.includes(user.role)) return;

                        return (
                            <ListItem
                                key={listItem.key}
                                onClick={() => {
                                    setSelected(listItem.key);
                                    navigate(listItem.navigateTo);
                                    // setDrawerOpen(false);
                                }}
                                sx={{
                                    ...style.ListStyle,
                                    ...style.center,
                                    background: selected === listItem.key ? theme.palette.primary.main : theme.palette.primary[100],
                                    color: selected === listItem.key ? theme.palette.primary[100] : theme.palette.primary.main,
                                }}
                            >
                                <ListItemIcon sx={{
                                    ...style.center,
                                    mt: 0.8,
                                    color: "inherit"
                                }} >
                                    {listItem.listIcon}
                                </ListItemIcon>
                                <ListItemText sx={{
                                    ...style.center,
                                }}>
                                    <Typography variant='body2'>{listItem.listText}</Typography>
                                </ListItemText>
                            </ListItem>
                        );

                    })}
                </List>
            </MenuList>
        </Box>
    )
}

export default DrawerContent;
