import React from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import Context from '../../../context';

const Popup = () => {

    const { openPopup, closePopup, setOpenPopup, titlePopup, setTitlePopup, childrenPopup, setChildrenPopup, propsPopup, setPropsPopup} = React.useContext(Context);

    return (
        <Dialog fullWidth open={openPopup} {...propsPopup}>
            <DialogTitle>{titlePopup}</DialogTitle>
            <IconButton onClick={() => closePopup()} sx={{ position: "absolute", top: 6, right: 6 }}>
                <CloseIcon />
            </IconButton>
            <DialogContent>
                {childrenPopup}
            </DialogContent>
        </Dialog>
    )
}

export default Popup;