import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import { Grid, Button, Paper, Typography, Box, IconButton } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';

const CourierRow = ({ delivery }) => {

    const PhoneIcon = createSvgIcon(
        <svg width="25" height="25" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M42.6813 35.7083L34.2126 28.0083C33.8123 27.6444 33.2862 27.4504 32.7455 27.4671C32.2048 27.4838 31.6918 27.71 31.3147 28.0978L26.3293 33.2249C25.1293 32.9958 22.7168 32.2437 20.2334 29.7666C17.7501 27.2812 16.998 24.8624 16.7751 23.6708L21.898 18.6833C22.2864 18.3064 22.5129 17.7933 22.5296 17.2524C22.5463 16.7116 22.3519 16.1854 21.9876 15.7853L14.2897 7.31868C13.9252 6.91734 13.4186 6.6739 12.8775 6.64005C12.3364 6.60621 11.8034 6.78463 11.3918 7.13743L6.87093 11.0145C6.51075 11.376 6.29576 11.8571 6.26677 12.3666C6.23552 12.8874 5.63968 25.2249 15.2063 34.7958C23.5522 43.1395 34.0063 43.7499 36.8855 43.7499C37.3063 43.7499 37.5647 43.7374 37.6334 43.7333C38.1428 43.7047 38.6237 43.4888 38.9834 43.127L42.8584 38.6041C43.2126 38.1937 43.3924 37.6612 43.3593 37.1201C43.3262 36.5791 43.0829 36.0724 42.6813 35.7083Z" fill="#1DA702" />
        </svg>,
        'PhoneIcon',
    );
    const WazeIcon = createSvgIcon(
        <svg width="24" height="25" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.209251 13.2555C-0.925125 19.9633 2.62644 26.3742 9.05066 29.5156C8.03503 32.1797 10.0194 35 12.8257 35C13.8597 34.9992 14.854 34.6017 15.6036 33.8894C16.3532 33.1772 16.8009 32.2045 16.8546 31.1719C17.3577 31.1867 21.8702 31.1719 22.8178 31.1219C22.8393 31.6521 22.9649 32.1728 23.1877 32.6544C23.4105 33.136 23.726 33.569 24.1162 33.9285C24.5064 34.2881 24.9637 34.5673 25.4618 34.75C25.96 34.9328 26.4892 35.0156 27.0194 34.9938C29.6647 34.8875 31.5467 32.2719 30.6913 29.6875C33.5983 28.6633 36.3585 26.9633 38.4741 24.1562C39.4897 22.8063 38.5116 20.8906 36.846 20.8906C33.228 20.8906 34.3288 16.6586 33.4749 12.2766C32.035 4.9375 24.3538 0 16.9342 0C8.928 0 1.53191 5.52109 0.209251 13.2555ZM10.2608 27.8344C6.97956 26.3359 3.90691 23.4039 2.73816 19.8547C-0.424343 10.2383 7.74988 2.04219 16.9342 2.04219C23.4538 2.04219 30.2405 6.37187 31.471 12.6672C32.2155 16.4883 31.0803 22.9289 36.8421 22.9289C34.8936 25.5156 32.285 27.0398 29.5194 27.9328C27.5928 26.2297 24.5296 26.7242 23.2827 29.0531C22.1718 29.1313 17.096 29.1453 16.4155 29.1172C16.1407 28.5805 15.7493 28.1121 15.2698 27.7465C14.7904 27.3808 14.2352 27.1272 13.6449 27.0041C13.0546 26.8811 12.4443 26.8918 11.8587 27.0355C11.2731 27.1792 10.7271 27.4521 10.2608 27.8344ZM23.4163 12.1195C23.4163 9.40547 19.4444 9.40469 19.4444 12.1195C19.4444 14.8344 23.4163 14.8336 23.4163 12.1195ZM14.3092 12.1195C14.3092 9.40547 10.3358 9.40469 10.3358 12.1195C10.3358 14.8344 14.3092 14.8344 14.3092 12.1195ZM23.8882 17.6422C24.1569 16.3188 22.1553 15.9094 21.8866 17.2352L21.8819 17.257C21.5585 18.9305 19.5499 20.6945 16.8725 20.6219C14.085 20.5484 12.2436 18.8867 11.8639 17.2805C11.5155 16.0266 9.62956 16.4711 9.87409 17.7492C10.2827 19.482 12.3124 22.593 17.0194 22.6633C20.3436 22.6633 23.3382 20.4883 23.8866 17.6437L23.8882 17.6422Z" fill="#31C6F7" />
        </svg>,
        'WazeIcon',
    );

    const getColor = {
        'pickup': 'primary',
        'dropoff': 'warning',
        'collect': 'error'
    }
    const getShortName = {
        'pickup': 'א',
        'dropoff': 'מ',
        'collect': 'ג'
    }
    return (
        <Box sx={{ p: 0.5 }}>
            <Paper elevation={3}>
                <Grid container>
                    <Grid item xs={3} sx={{ p: 1 }}>
                        <Link to={`/courier/delivery/${delivery.id}`}>
                            <Button variant="contained" size="small" color={getColor[delivery.courierType]}
                                sx={{ px: 0.5, py: 1.5, borderRadius: '20px', boxShadow: 2 }}>
                                <Typography variant='p'>{getShortName[delivery.courierType]}</Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='p' color='primary'>{delivery.name}</Typography>
                        <br />
                        <Typography variant='caption' color='primary'>{delivery.address}</Typography>
                        {delivery.courierType == "pickup" && <br />}
                        {delivery.courierType == "pickup" && <Typography variant='caption' color='primary'>
                            <strong style={{fontSize:"80%"}}>נשלח ל: </strong>
                            {delivery.city}
                        </Typography>}
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container sx={{ height: 1 }} direction="row" justifyContent="center" alignItems="center">
                            <Grid item xs={6}>
                                <a href={`tel:${delivery.phone}`} target='_blank'>
                                    <IconButton>
                                        <PhoneIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href={`https://waze.com/ul?q=${delivery.waze}&z=10&navigate=yes`} target='_blank'>
                                    <IconButton>
                                        <WazeIcon />
                                    </IconButton>
                                </a>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
};

export default CourierRow;