import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import { AppBar, IconButton, Toolbar, useMediaQuery, Box, Stack, Grid, Typography, Popover, List, ListItemButton, ListItemText } from '@mui/material';

// project import
import AppBarStyled from './AppBarStyled';

import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined, UserOutlined, SwapOutlined } from '@ant-design/icons';

import IconBtn from '../../../global/IconBtn';
import Context from '../../../context';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {

    let navigate = useNavigate();

    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    const { user, setUser, companies, setCompanies } = React.useContext(Context);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const companiesList = React.useMemo(() => {
        if (companies) {
            return companies.map(c => ({ value: c.id, label: c.name }));
        } else {
            return [];
        }
    }, [companies]);

    const companyName = React.useMemo(() => {
        if (companies) {
            let companyID = localStorage.getItem('uc');
            let company = companies.find(c => c.id == companyID);
            return company ? company.name : '';
        } else {
            return user.companies[0].name;
        }
    }, [user, companies]);

    // common header
    const mainHeader = (
        <Toolbar>
            <Grid container alignItems='center' justifyContent='space-between'>
                <Grid item xs={1}>
                    <IconBtn tooltip="תפריט" onClick={handleDrawerToggle} icon={!open ? <MenuFoldOutlined style={{ fontSize: '18px' }} /> : <MenuUnfoldOutlined style={{ fontSize: '18px' }} />} />
                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={3}>
                    {/* <Typography variant='h6' align='right' color='primary'>
                        {companyName}
                    </Typography> */}
                </Grid>
                <Grid item xs={4}>
                    <Stack
                        direction="row"
                        justifyContent="end"
                        alignItems="center"
                        spacing={2}
                        sx={{ width: 1, height: 1 }}
                    >
                        <Typography variant='h6' align='right' color='primary'>
                            {companyName}
                        </Typography>

                        <IconBtn tooltip="החלפת חברה" icon={<SwapOutlined style={{ fontSize: '18px' }} />} disabled={!companies} onClick={(e) => {
                            setAnchorEl(e.currentTarget);
                        }} />
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                        >
                            <List>
                                {companiesList.map(c => (
                                    <ListItemButton key={c.value}>
                                        <ListItemText primary={c.label} onClick={() => {
                                            localStorage.setItem('uc', c.value);
                                            window.location.reload();
                                        }} />
                                    </ListItemButton>
                                ))}
                            </List>
                        </Popover>

                        <IconBtn tooltip="פרופיל משתמש" icon={<UserOutlined style={{ fontSize: '18px' }} />} disabled />
                        <IconBtn tooltip="יציאה מהמערכת" icon={<LogoutOutlined style={{ fontSize: '18px' }} />} onClick={() => {
                            localStorage.clear();
                            window.location.reload();
                        }} />
                    </Stack>
                </Grid>
            </Grid>
        </Toolbar>
    );

    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            bgcolor: theme.palette.background.default,
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

export default Header;
