import React from 'react';

import { Stack, CircularProgress } from '@mui/material';

const NoData = ({height}) => {
    return (
        <Stack direction="column" alignItems="center" justifyContent="center" sx={{ width: 1, height: height }}>
            <CircularProgress />
        </Stack>
    )
}

// default props
NoData.defaultProps = {
    height: "65vh"
}
export default NoData;