import React from 'react';
import { Button, Stack, Grid, Box, Typography } from '@mui/material';

import { downloadPDF } from '../../../api/http';
import Context from '../../../context';

const PrintDialog = ({ array, printed }) => {

    const { closePopup } = React.useContext(Context);

    const labeles = [1, 2, 3, 4, 5, 6, 7, 8];

    const [deliveriesArray, setDeliveriesArray] = React.useState(array);
    React.useEffect(() => {
        setDeliveriesArray(array);
    }, [array])

    return (
        <Box>
            <Typography variant="h6" align='center'>
                נא לבחור מדבקה ראשונה להדפסה
            </Typography>
            <Stack direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ width: 1 }}>
                <Grid container sx={{ width: 0.5 }}>
                    {labeles.map(x => {
                        return (
                            <Grid key={x} item xs={6}>
                                <Stack direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: 1 }}>
                                    <Button variant="outlined" size="large" fullWidth
                                        onClick={() => {
                                            downloadPDF(`deliveries/print`, 'deliveries', 'POST', {
                                                deliveries: deliveriesArray,
                                                start: x,
                                                printed: printed
                                            });
                                            closePopup();
                                        }}>
                                        {x}
                                    </Button>
                                </Stack>
                            </Grid>
                        )
                    })}
                </Grid>
            </Stack>
        </Box>
    )
}

// set default props
PrintDialog.defaultProps = {
    printed: false
}

export default PrintDialog;