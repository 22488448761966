import React from 'react';
import { useNavigate } from "react-router-dom";
import { useQuery } from 'react-query';

import { Grid, Button, Paper, Typography, ButtonGroup, IconButton, Box } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import http from '../../../api/http';
import CourierRow from './CourierRow';
import Context from '../../../context';

const Courier = () => {

  let navigate = useNavigate();
  const { user } = React.useContext(Context);

  const { data, isLoading, isError, isSuccess } = useQuery('deliveriesForCourier', () => http.get('deliveries/courier/all'));

  const [deliveryList, setDeliveryList] = React.useState([]);

  function filter(type) {
    switch (type) {
      case 'pickup':
        setDeliveryList(data.filter(d => d.courierType == 'pickup'));
        break;
      case 'dropoff':
        setDeliveryList(data.filter(d => d.courierType == 'dropoff' || d.courierType == 'collect'));
        break;
      default:
        setDeliveryList(data);
        break;
    }
  }

  React.useEffect(() => {
    if (isSuccess) {
      setDeliveryList(data)
    }
  }, [data])

  return (
    <Box sx={{ width: { xs: 1, sm: 500 }, m: 'auto' }}>
      <ButtonGroup size="large" fullWidth variant="contained">
        <Button sx={{ width: 0.1 }} onClick={() => {
          if(user?.role == 'courier') {
            localStorage.clear();
            navigate('/');
            window.location.reload();
          } else {
            navigate('/company/deliveries');
          }
        }}><LogoutIcon /></Button>
        <Button sx={{ width: 0.3 }} onClick={() => setDeliveryList(data)}>הכל</Button>
        <Button sx={{ width: 0.3 }} onClick={() => filter('pickup')}>איסופים</Button>
        <Button sx={{ width: 0.3 }} onClick={() => filter('dropoff')}>מסירות</Button>
      </ButtonGroup>
      <Paper elevation={0} sx={{ mt: 2, height: '85vh', overflowY: 'auto' }}>
        {deliveryList && deliveryList.map(d => <CourierRow key={d.id} delivery={d} />)}
      </Paper>
    </Box>
  )
}

export default Courier;