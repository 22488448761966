import React from 'react';

import { FormControl, FormHelperText, OutlinedInput, InputLabel, Tooltip, CircularProgress, Stack, TextField } from '@mui/material';

import { formatDateJS, formatMonthJS } from '../../utils/func';

const GenericInput = ({
    name,
    label,
    type,
    allFieldsSetting,
    required,
    controlProps,
    inputMuiProps,
    inputProps,
    inputs,
    setInputs,
    handleChange,
    error,
    errorText,
    disabled,
    validate,
    onBlur,
    tooltip,
    loading,
    multiline
}) => {

    const [errorS, setErrorS] = React.useState(false);
    const [helperText, setHelperText] = React.useState(errorText);
    React.useEffect(() => {
        setErrorS(error);
        setHelperText(errorText);
    }, [error]);

    const typeForHtml = () => {
        switch (type) {
            // case 'date':
            //     return inputs[name] && !formatDateJS(inputs[name])
            //         ? 'text'
            //         : 'date';
            case 'currency':
                return 'number';
            case 'currencyIL':
                return 'number';
            case 'percent':
                return 'number';
            default:
                return type;
        }
    }

    const value = () => {
        if (inputs && inputs[name]) {
            switch (type) {
                // לא מבין למה זה לא עובד
                // case 'date':
                //     return formatDateJS(inputs[name]);
                case 'month':
                    return formatMonthJS(inputs[name]);
                case 'currency':
                    return Math.round(inputs[name] * 100) / 100;
                default:
                    return inputs[name];
            }
        } else {
            switch (type) {
                case 'currency':
                    return '0.00';
                case 'currencyIL':
                    return '0.00';
                case 'percent':
                    return '0.00';
                default:
                    return '';
            }

        }
    }

    const chackDisabled = () => {
        if (typeof disabled === 'function') {
            if (disabled({ inputs, setInputs })) {
                return true;
            } else {
                return false;
            }
        } else {
            return disabled;
        }
    }

    const isStartAdornment = React.useMemo(() => {
        return type == 'currencyIL' || type == 'percent';
    }, [type]);

    const floatNumberInputProps = React.useMemo(() => {
        if (type == 'currencyIL' || type == 'percent') {
            return {
                inputMode: 'decimal',
                pattern: '[0-9]*',
                step: '0.01',
            }
        } else {
            return {};
        }
    }, [type]);

    const textField = <TextField
        error={errorS}
        disabled={chackDisabled()}
        variant="outlined"
        label={label}
        InputLabelProps={{
            shrink: true,
        }}
        fullWidth
        sx={{ maxWidth: "100%" }}
        type={typeForHtml()}
        // size="small"
        name={name}
        required={required}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        {...allFieldsSetting}
        inputProps={{ ...floatNumberInputProps, ...inputProps }}
        InputProps={{
            startAdornment: !isStartAdornment ? null : <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: 1 }}>
                {type == 'currencyIL' && <span>₪</span>}
                {type == 'percent' && <span>%</span>}
            </Stack>,
            endAdornment: loading ? <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: 1 }}>
                <CircularProgress size={15} />
            </Stack> : null
        }}
        value={value() || ''}
        // value={inputs[name] || ''}
        onChange={handleChange}
        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
        onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
        onBlur={(e) => {

            if(type == 'currencyIL' || type == 'percent'){
                setInputs({ ...inputs, [name]: parseFloat(e.target.value).toFixed(2) });
            }

            if (validate) validate({
                value: e.target.value,
                inputs,
                setInputs,
                setError: setErrorS,
                setMessage: setHelperText
            })

            if (onBlur) onBlur({
                value: e.target.value,
                inputs,
                setInputs,
                setError: setErrorS,
                setMessage: setHelperText
            })
        }}
        helperText={errorS && helperText ? helperText : ''}
    />

    // const body = <FormControl
    //     error={errorS}
    //     disabled={disabled}
    //     variant="outlined"
    //     fullWidth
    //     sx={{ maxWidth: "100%" }}
    //     {...controlProps}
    // >
    //     <InputLabel shrink>
    //         {label}
    //     </InputLabel>
    //     <OutlinedInput
    //         error={errorS}
    //         disabled={disabled}
    //         //type={typeForHtml()}
    //         type={type}
    //         size="small"
    //         name={name}
    //         required={required}
    //         {...inputMuiProps}
    //         {...allFieldsSetting}
    //         inputProps={inputProps}
    //         endAdornment={loading && <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: 1 }}>
    //             <CircularProgress size={15} />
    //         </Stack>}
    //         value={value() || ''}
    //         // value={inputs[name] || ''}
    //         onChange={handleChange}
    //         onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
    //         onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault() }}
    //         onBlur={(e) => {

    //             if (validate) validate({
    //                 value: e.target.value,
    //                 inputs,
    //                 setInputs,
    //                 setError: setErrorS,
    //                 setMessage: setHelperText
    //             })

    //             if (onBlur) onBlur({
    //                 value: e.target.value,
    //                 inputs,
    //                 setInputs,
    //                 setError: setErrorS,
    //                 setMessage: setHelperText
    //             })
    //         }}
    //     />
    //     {errorS && <FormHelperText>{helperText}</FormHelperText>}
    // </FormControl>

    return (
        <>
            {tooltip && <Tooltip title={tooltip} arrow>{textField}</Tooltip>}
            {!tooltip && textField}
        </>
    )
}

GenericInput.defaultProps = {
    type: 'text',
    controlProps: {},
    inputMuiProps: {},
    inputProps: {},
    required: false,
    disabled: false,
    error: false,
    errorText: null,
    validate: null,
    onBlur: null,
    tooltip: null,
    loading: false,
    multiline: false,
}

export default GenericInput;
