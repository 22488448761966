import React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import { Checkbox, Card, Grid, Button, Typography, IconButton, Stack, Tooltip, Badge } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import http from '../../../api/http';
import Context from '../../../context';
import GenericTable from '../../../components/GenericTable';
import GenericForm from '../../../components/GenericForm';
import { formatCurrencyIL } from '../../../utils/func';
import RowCollaapse from './RowCollaapse';
import PrintDialog from '../../Customer/Deliveries/PrintDialog';

const Type = ({ type }) => {
    switch (type) {
        case 1:
            return <span>רגיל</span>;
        case 2:
            return <span>אקספרס</span>;
        case 3:
            return <span>כפול</span>;
        default:
            return <span>רגיל</span>;
    }
};

const DeliveriesCustomers = () => {

    const getDeliveriesForCopmany = useQuery(['deliveriesForCompany'], () => http.get('deliveries/get-for-company'));

    // console.log(getDeliveriesForCopmany.data);

    const queryClient = useQueryClient();
    const { getObj, popup } = React.useContext(Context);

    const [searchInpiuts, setSearchInputs] = React.useState({});
    const [dataToShow, setDataToShow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);

    const [printAllArray, setPrintAllArray] = React.useState([]);
    const [printSelectedArray, setPrintSelectedArray] = React.useState([]);

    React.useEffect(() => {
        if (getDeliveriesForCopmany.data) {

            const dataFromServer = getDeliveriesForCopmany.data[0];

            const filteredData = dataFromServer.filter((item) => {
                let isFound = true;
                Object.keys(searchInpiuts).forEach((key) => {

                    if (searchInpiuts[key] == '0' || searchInpiuts[key] == '' || searchInpiuts[key] == null || searchInpiuts[key] == undefined) {
                        return;
                    }
                    // console.log(searchInpiuts);
                    // console.log(item);
                    switch (key) {
                        case 'user_company_id':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'id':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'status':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'dateStart':
                            let dateStart = new Date(searchInpiuts[key]);
                            dateStart.setDate(dateStart.getDate() - 1);
                            if (new Date(item["date"]) < dateStart) isFound = false;
                            break;
                        case 'dateEnd':
                            if (new Date(item["date"]) > new Date(searchInpiuts[key])) isFound = false;
                            break;
                        case 'name':
                            if (!item["t_name"].includes(searchInpiuts[key])) isFound = false;
                            break;
                        case 'phone':
                            if (item["t_phone"] != searchInpiuts[key]) isFound = false;
                            break;
                        default:
                            isFound = true;
                    }

                });
                return isFound;
            });

            setCount(filteredData.length);
            setDataToShow(filteredData.slice(page * 50, page * 50 + 50));

        } else {
            setDataToShow([]);
        }
    }, [getDeliveriesForCopmany.status, getDeliveriesForCopmany.isFetching, page, searchInpiuts]);

    React.useEffect(() => {
        if (dataToShow.length > 0) {
            let arr = [];
            dataToShow.map(d => {
                if (d.status == 2 && d.printed == 0) {
                    arr.push(d.id)
                };
            })
            setPrintAllArray(arr);
        }
    }, [dataToShow])


    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card elevation={0} sx={{ borderRadius: 1, p: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={8}>
                            <Stack
                                direction="row"
                                spacing={3}
                                justifyContent="start"
                                alignItems="center"
                                sx={{ mb: 2 }}
                            >
                                <GenericForm
                                    fields={[
                                        { label: "שם לקוח", name: "user_company_id", type: "text", size: 1.5 },
                                        { label: "מספר משלוח", name: "id", type: "text", size: 1.5 },
                                        { label: "סטטוס", name: "status", type: "lookup", lookup: "global_status", size: 1.5 },
                                        { label: "מתאריך", name: "dateStart", type: "date", size: 1.5 },
                                        { label: "עד תאריך", name: "dateEnd", type: "date", size: 1.5 },
                                        { label: "שם יעד", name: "name", type: "text", size: 1.5 },
                                        { label: "טלפון יעד", name: "phone", type: "phone", size: 1.5 },
                                        { label: "חיפוש", type: "submit", size: 1.5, variant: "contained" }
                                    ]}
                                    // initInputs={searchInpiuts}
                                    // setInitInput={setSearchInputs}
                                    onSubmit={(values) => setSearchInputs(values)}
                                />
                                {Object.keys(searchInpiuts).length > 0 &&
                                    <Button
                                        variant="text"
                                        type="button"
                                        size='small'
                                        color='error'
                                        endIcon={<DeleteIcon fontSize='small' />}
                                        onClick={() => {
                                            setSearchInputs({});
                                        }}
                                        sx={{ minWidth: 120}}
                                    >
                                        ניקוי חיפוש
                                    </Button>
                                }
                            </Stack>
                        </Grid>
                        <Grid item xs={4}>
                            <Stack direction='row' justifyContent="end" alignItems="center" spacing={1} sx={{ height: 1 }}>
                                <Tooltip color='primary' arrow title="הדפסת משלוחים נבחרים">
                                    <IconButton size='large' onClick={() => {
                                        if (printSelectedArray.length < 1) return;
                                        popup({
                                            title: "הדפסת משלוחים נבחרים",
                                            content: <PrintDialog array={printSelectedArray} printed={true} />,
                                            onClose: () => {
                                                queryClient.refetchQueries(['deliveriesForCustomer'], { active: true, exact: true });
                                            }
                                        })
                                    }}>
                                        <Badge badgeContent={printSelectedArray.length} color="secondary" showZero>
                                            <LocalPrintshopOutlinedIcon fontSize='large' color={printSelectedArray.length > 0 ? "primary" : "secondary"} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip color='primary' arrow title="הדפסת כל המשלוחים">
                                    <IconButton size='large' onClick={() => {
                                        if (printAllArray.length < 1) return;
                                        popup({
                                            title: "הדפסת כל המשלוחים שלא הודפסו",
                                            content: <PrintDialog array={printAllArray} />,
                                            onClose: () => {
                                                queryClient.refetchQueries(['deliveriesForCustomer'], { active: true, exact: true });
                                            }
                                        })
                                    }}>
                                        <Badge badgeContent={printAllArray.length} color="secondary" showZero>
                                            <LocalPrintshopIcon fontSize='large' color={printAllArray.length > 0 ? "primary" : "secondary"} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {count == 0 && <Typography variant="h4" align="center" color="error" sx={{ my: 3 }}>לא נמצאו משלוחים</Typography>}
                {count > 0 && <Card elevation={0} sx={{ pt: 0, pb: 7, px: 2, borderRadius: 1, height: "85vh" }}>
                    <GenericTable
                        columns={[
                            {
                                cb: ({ row }) =>
                                    <Checkbox size='small'
                                        checked={printSelectedArray.includes(row.id) ? true : false}
                                        disabled={![1, 2].includes(row.status) ? true : false}
                                        sx={{ py: 0, '& .MuiSvgIcon-root': { fontSize: 18 } }}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setPrintSelectedArray([...printSelectedArray, row.id])

                                            } else {
                                                setPrintSelectedArray(printSelectedArray.filter(p => p != row.id))
                                            }
                                        }} />
                            },
                            { label: "הודפס?", key: "printed", type: "boolean" },
                            { label: "שם לקוח", key: "user_company_id", type: "text", sx: { fontSize: "150%", fontWeight: 600 } },
                            { label: "מספר משלוח", key: "id", type: "copy", sx: { fontSize: "150%", fontWeight: 600 } },
                            { label: "יצירה", key: "creation", type: "datetime" },
                            {
                                label: "סטטוס",
                                cb: ({ row }) => <Typography variant="body1" align='center' color="white" sx={{ py: 1, my: 1, bgcolor: `${getObj("global_status", row.status).color}.main` }}>{getObj("global_status", row.status).name}</Typography>
                                // cb: ({ row }) => <Chip label={getObj("global_status", row.status).name} color={getObj("global_status", row.status).color} size="medium" />
                            },
                            {
                                label: "מוצא",
                                cb: ({ row }) => <>
                                    <Typography variant="body1">
                                        {row.f_name}
                                    </Typography>
                                    <Typography variant="caption">
                                        {row.f_street} {row.f_num} {row.f_city}
                                    </Typography>
                                </>
                            },
                            {
                                label: "יעד",
                                cb: ({ row, openCollapse }) => <>
                                    <Typography variant="body1">
                                        {row.t_name}
                                    </Typography>
                                    <Typography variant="caption">
                                        {row.t_street} {row.t_num} {row.t_city}
                                    </Typography>
                                    {openCollapse && <Typography variant="caption" component="p">
                                        {row.t_floor ? "קומה: " + row.t_floor : ""} {row.t_appartment ? "דירה: " + row.t_appartment : ""} {row.t_entrance ? "כניסה: " + row.t_entrance : ""}
                                    </Typography>}
                                    {openCollapse && <Typography variant="caption" component="p">
                                        {row.t_note}
                                    </Typography>}
                                </>
                            },
                            { label: "הערות", key: "note_user", type: "small" },
                            { label: "איש קשר", key: "t_phone", type: "copy" },
                            { label: "חבילות", key: "packeges" },
                            { label: "משלוח", cb: ({ row }) => <Type type={row.type} /> },
                            { label: "תאריך ערך", key: "date", type: "date" },
                            { label: "גוביינא", cb: ({ row }) => row.collect > 0 ? <Typography variant="body1" color="error">{formatCurrencyIL(row.collect)}</Typography> : <Typography variant="body2" sx={{ fontWeight: 700 }}>✗</Typography> },
                            { label: "איסוף", key: "pick_up_time", type: "datetime", color: "info.main" },
                            { label: "מסירה", key: "delivery_time", type: "datetime", color: "success.main" },
                            {
                                collapse: ({ row, openCollapse }) => {
                                    return openCollapse ? <RowCollaapse row={row} /> : null;
                                },
                                cb: ({ row, openCollapse, setOpenCollapse }) => <IconButton
                                    disabled={![1, 2, 3].includes(row.status)}
                                    color="primary"
                                    onClick={() => {
                                        setOpenCollapse(open => !open);
                                    }}
                                >
                                    {!openCollapse ? <ExpandMoreIcon fontSize='medium' /> : <ExpandLessIcon fontSize='medium' />}
                                </IconButton>
                            }
                        ]}
                        data={dataToShow}
                        title="משלוחים"
                        counter={count}
                        pagination
                        page={page}
                        setPage={setPage}
                    />
                </Card>}
            </Grid>
        </Grid>
    )
}

export default DeliveriesCustomers;