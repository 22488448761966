import React, { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';

import { Checkbox, Card, Grid, Typography, IconButton, Stack, Tooltip, Badge, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

import http from '../../../api/http';
import Context from '../../../context';
import GenericTable from '../../../components/GenericTable';
import GenericForm from '../../../components/GenericForm';
import RowCollaapse from './RowCollaapse';

import { formatDateTime, formatDate, formatCurrencyIL } from '../../../utils/func';

const DeliveriesApi = () => {

  const getDeliveriesFromApi = useQuery(['deliveriesFromApi'], () => http.get('deliveries/external/all'));

  const queryClient = useQueryClient();
  const { snackbar, confirm } = React.useContext(Context);

  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(0);

  const [deleteArray, setDeleteArray] = React.useState([]);
  console.log(deleteArray);

  React.useEffect(() => {
    if (getDeliveriesFromApi.isSuccess) {
      setCount(getDeliveriesFromApi.data.length);
    }
  }, [getDeliveriesFromApi]);

  const googleAddress = useCallback((row) => {
    return parseGoogleAddress(row.google_address);
  }, []);

  const deleteSelected = useMutation((obj) => http.put(`deliveries/external/meny/3`, obj), {
    onSuccess: (data) => {
      queryClient.refetchQueries(['deliveriesFromApi'], { active: true, exact: true });
      snackbar(`משלוחים נמחקו בהצלחה `, 'success');
    },
    onError: (error) => {
      snackbar("אירעה שגיאה במחיקת המשלוחים", 'error');
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>

        {count == 0 && <Typography variant="h4" align="center" color="error" sx={{ my: 3 }}>לא נמצאו משלוחים</Typography>}
        {count > 0 && <Card elevation={0} sx={{ pt: 0, pb: 7, px: 2, borderRadius: 1, height: "85vh" }}>
          <GenericTable
            columns={[
              {
                label: <Checkbox size='small'
                  sx={{ py: 0, '& .MuiSvgIcon-root': { fontSize: 18, color: "white" } }}
                  checked={deleteArray.length > 0 ? true : false}
                  // color=""
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (getDeliveriesFromApi.isSuccess) {
                        setDeleteArray(getDeliveriesFromApi.data.map(p => p.id))
                      }
                    } else {
                      setDeleteArray([])
                    }
                  }} />,
                cb: ({ row }) =>
                  <Checkbox size='small'
                    checked={deleteArray.includes(row.id) ? true : false}
                    sx={{ py: 0, '& .MuiSvgIcon-root': { fontSize: 18 } }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDeleteArray([...deleteArray, row.id])
                      } else {
                        setDeleteArray(deleteArray.filter(p => p != row.id))
                      }
                    }} />
              },
              { label: "זמן קליטה", key: "creation", type: "datetime" },
              { label: "שם נמען", key: "name", type: "text" },
              { label: "טלפון", key: "phone", type: "copy" },
              { label: "כתובת שנקלטה", key: "address", type: "text" },
              {
                label: "כתובת גוגל",
                cb: ({ row }) => {
                  let address = googleAddress(row);
                  return `${address.street} ${address.num}, ${address.city}`;
                },
              },
              {
                label: "הערות",
                cb: ({ row }) => {
                  return clearUndefined(row.note);
                },
              },
              {
                collapse: ({ row, openCollapse }) => {
                  let address = googleAddress(row);
                  return openCollapse ? <RowCollaapse row={row} googleAddress={address} /> : null;
                },
                cb: ({ row, openCollapse, setOpenCollapse }) => <IconButton
                  color="primary"
                  onClick={() => {
                    setOpenCollapse(open => !open);
                  }}
                >
                  {!openCollapse ? <ExpandMoreIcon fontSize='medium' /> : <ExpandLessIcon fontSize='medium' />}
                </IconButton>
              }
            ]}
            data={getDeliveriesFromApi.isSuccess ? getDeliveriesFromApi.data : []}
            title="משלוחים לאישור משרת חיצוני"
            counter={count}
            pagination
            page={page}
            setPage={setPage}
            actions={deleteArray.length == 0 ? null : [
              {
                label: "מחיקת נבחרים",
                color: "error",
                variant: "contained",
                icon: <DeleteIcon />,
                onClick: () => {
                  deleteSelected.mutate({ ids: deleteArray });
                }
              }
            ]}
          />
        </Card>}
      </Grid>
    </Grid>
  )
}

export default DeliveriesApi;

function parseGoogleAddress(google_address) {
  if (!google_address || google_address.length == 0) return {};
  let address = JSON.parse(google_address);
  // console.log(address);
  let street = address.find((item) => item.types[0] === "route");
  let num = address.find((item) => item.types[0] === "street_number");
  let city = address.find((item) => item.types[0] === "locality");
  let area = address.find((item) => item.types[0] === "administrative_area_level_2");
  return {
    street: street ? street.long_name : '',
    num: num ? num.long_name : '',
    city: city ? city.long_name : '',
    area: area ? area.long_name : ''
  };
}

function clearUndefined(str) {
  return str.replace(/undefined/g, '');
}
