import React from 'react';
import { useQuery } from 'react-query';

import { Grid, Card, Typography, IconButton, Button, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

import RowCollaapse from './RowCollaapse';

import http from '../../../api/http';
import GenericForm from '../../../components/GenericForm';
import GenericTable from '../../../components/GenericTable';






const Locations = ({ }) => {

    const locations = useQuery(['locations'], () => http.get('locations'));
    console.log("locations:", locations.data);

    const [searchInpiuts, setSearchInputs] = React.useState({});
    const [dataToShow, setDataToShow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    React.useEffect(() => {
        if (locations.data) {

            const dataFromServer = locations.data;
            const filteredData = dataFromServer.filter((item) => {
                let isFound = true;
                Object.keys(searchInpiuts).forEach((key) => {

                    if (searchInpiuts[key] == '0' || searchInpiuts[key] == '' || searchInpiuts[key] == null || searchInpiuts[key] == undefined) {
                        return;
                    }

                    switch (key) {
                        case 'name':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'area':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'city':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'street':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'num':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'floor':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'appartment':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'entrance':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;
                        case 'phone':
                            if (item[key] != searchInpiuts[key]) isFound = false;
                            break;

                        default:
                            isFound = true;
                    }

                });
                return isFound;
            });

            setCount(filteredData.length);
            setDataToShow(filteredData.slice(page * 50, page * 50 + 50));

        } else {
            setDataToShow([]);
        }
    }, [locations.status, locations.isFetching, page, searchInpiuts]);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Card elevation={0} sx={{ borderRadius: 1, p: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack
                                direction="row"
                                spacing={3}
                                justifyContent="start"
                                alignItems="center"
                                sx={{ mb: 2 }}
                            >
                                <GenericForm
                                    fields={[
                                        { label: "שם מקום", name: "name", type: "text", size: 1.3 },
                                        { label: "איזור", name: "area", type: "text", size: 1.3 },
                                        { label: "עיר", name: "city", type: "text", size: 1.3 },
                                        { label: "רחוב", name: "street", type: "text", size: 1.3 },
                                        { label: " בית", name: "num", type: "text", size: 1 },
                                        { label: "קומה", name: "floor", type: "text", size: 1 },
                                        { label: "דירה", name: "appartment", type: "text", size: 1 },
                                        { label: "כניסה", name: "entrance", type: "text", size: 1 },
                                        { label: "טלפון", name: "phone", type: "text", size: 1 },

                                        {
                                            label: "חיפוש", type: "submit", size: 1.3, variant: "contained",

                                        },

                                    ]}
                                    onSubmit={(values) => setSearchInputs(values)}
                                />
                                {Object.keys(searchInpiuts).length > 0 &&
                                    <Button
                                        variant="contained"
                                        type="button"
                                        size='medium'
                                        color='error'
                                        endIcon={<DeleteIcon fontSize='small' />}
                                        onClick={() => {
                                            setSearchInputs({});
                                           
                                        }}
                                    >
                                        ניקוי חיפוש 
                                    </Button>
                                }
                            </Stack>
                        </Grid>

                    </Grid>
                </Card>
            </Grid>
            <Grid item xs={12}>
                {count == 0 && <Typography variant="h4" align="center" color="error" sx={{ my: 3 }}>לא נמצאו מיקומים</Typography>}
                {count > 0 && <Card elevation={0} sx={{ pt: 0, pb: 7, px: 2, borderRadius: 1, height: "85vh" }}>
                    <GenericTable
                        columns={[
                            { label: "שם מקום", key: "name", type: "text" },
                            { label: "איזור ", key: "area", type: "text" },
                            { label: "עיר", key: "city", type: "text" },
                            { label: "רחוב", key: "street", type: "text" },
                            { label: "מספר בית", key: "num", type: "text" },
                            { label: "קומה", key: "floor", type: "text" },
                            { label: "דירה", key: "appartment", type: "text" },
                            { label: "כניסה", key: "entrance", type: "text" },
                            { label: "טלפון", key: "phone", type: "text" },
                            { label: "הערה לכתובת", key: "note", type: "readmore" },
                            {
                                collapse: ({ row, openCollapse }) => {
                                    return openCollapse ? <RowCollaapse row={row} /> : null;
                                },
                                cb: ({ row, openCollapse, setOpenCollapse }) => <IconButton
                                    color="primary"
                                    onClick={() => {
                                        setOpenCollapse(open => !open);
                                    }}
                                >
                                    {!openCollapse ? <ExpandMoreIcon fontSize='medium' /> : <ExpandLessIcon fontSize='medium' />}
                                </IconButton>
                            },


                        ]}
                        data={dataToShow}
                        title="מיקומים"
                        counter={count}
                        pagination
                        page={page}
                        setPage={setPage}

                    />
                </Card>}
            </Grid>
        </Grid>

    )
}

export default Locations;