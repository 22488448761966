import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const GenericAutocomplete = ({ name, label, options, inputs, setInputs }) => {
    return (
        <Autocomplete
            disablePortal
            disableClearable
            fullWidth
            size='small'
            options={options}
            getOptionLabel={(option) => `${option.label}`}
            renderInput={(params) => <TextField size='small' {...params} label={label} />}
            name={name}
            value={inputs[name] || ''}
            onChange={(e, value) => setInputs({ ...inputs, [name]: value })}
            inputValue={inputs[name] || ''}
            onInputChange={(event, newInputValue) => {
                setInputs({ ...inputs, [name]: newInputValue })
            }}
        />
    )
}

export default GenericAutocomplete;