import React from 'react'

import { Typography, Stack, } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { formatDateTimeObj } from '../../../utils/func';

const ShowTracking = ({data,title}) => {
    return (
        <>
            <Typography variant="subtitle2" align="center" color='primary'>{title} :
            </Typography>
            <Typography variant="body2" align="center" >
                {formatDateTimeObj(data).date}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} sx={{ width: 1, height: 1 }} >
                <AccessTimeFilledIcon sx={{ fontSize: 13 }} />
                <Typography variant='caption' sx={{ fontSize: "85%" }}>
                    {formatDateTimeObj(data).time}
                </Typography>
            </Stack>
        </>
    )
}

export default ShowTracking