// material-ui
import { Toolbar, Box, Typography } from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
// ==============================|| DRAWER HEADER ||============================== //

const DrawerHeader = ({ open }) => {
    return (
        <Toolbar>
            <Box sx={{ width: 1, my:5, display: "flex", justifyContent: "center" }}>
                <LocalShippingIcon
                    color="primary"
                    sx={{
                        fontSize: "100px"
                    }}
                />
            </Box>
        </Toolbar>
    );
};

export default DrawerHeader;
