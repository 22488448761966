import React from 'react';
import { useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Toolbar, useMediaQuery, Alert, Snackbar, Typography } from '@mui/material';

import Drawer from './Drawer';
import Header from './Header';
import Confirm from './Confirm';
import Popup from './Popup';
import SmallPopup from './SmallPopup';
import Context from '../../context';

// ==============================|| MAIN LAYOUT ||============================== //

const Layout = () => {

    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('md')); // xl

    const [open, setOpen] = useState(true);
    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    // set media wise responsive drawer
    useEffect(() => {
        setOpen(!matchDownLG);
    }, [matchDownLG]);

    /* Snackbar */
    const { openSnackbar, setOpenSnackbar, snackbarMessage, snackbarMessageType, openConfirm, openPopup, openSmallPopup } = useContext(Context);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <>
            {openConfirm && <Confirm />}
            {openPopup && <Popup />}
            {openSmallPopup && <SmallPopup />}

            <Box sx={{ display: 'flex', width: '100%' }}>
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                <Drawer open={open} setOpen={setOpen} handleDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ width: '80%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                    <Toolbar />
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={5000}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Alert
                            onClose={handleClose}
                            severity={snackbarMessageType}
                            variant="filled"
                            sx={{ width: '100%', color: 'white' }}
                        >
                            <Typography variant="h6">{snackbarMessage}</Typography>
                        </Alert>
                    </Snackbar>
                    <Outlet />
                </Box>
            </Box>
        </>
    );
};

export default Layout;
