import { useState, useEffect, useTransition, useContext } from 'react';

// material-ui
import {
    TableContainer,
    Table,
    TableHead,
    TableFooter,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    Collapse,
    Stack,
    Grid,
    Box,
    LinearProgress,
    Typography,
    ButtonGroup,
    Button,
    Pagination,
    TablePagination,
    Tooltip
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

import { useTheme } from '@mui/material/styles';
import { formatCurrency, formatCurrencyIL, formatMinusCurrencyIL, formatDate, formatDateTime, formatDateTimeObj, formatReadMore, formatMonth, formatPercent } from '../../utils/func';
import Context from '../../context';
import React from 'react';

const Header = ({ title, actions, count, countForPagination, rowsPerPage, page, setPage  }) => {

    const pagination = countForPagination > rowsPerPage ? true : false;

    const actionDisabled = (action) => {
        if (action.disabled) {
            if (typeof action.disabled === 'function') {
                if (action.disabled()) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return action.disabled;
    }

    return (
        <Grid container spacing={2} alignItems="center" sx={{ py: pagination ? 0 : 1 }}>
            <Grid item xs={3}>
                <Typography variant="h5">
                    {title}
                    {count && <>&nbsp;<Typography variant="caption">({count})</Typography></>}
                </Typography>
            </Grid>
            <Grid item xs={pagination ? 5 : 9}>
                <Stack direction="row"
                    justifyContent={pagination ? "center" : "end"}
                    alignItems="center"
                    spacing={2}>
                    {(actions) && <ButtonGroup size='small'>
                        {actions.map((action, index) => (
                            <React.Fragment key={index}>
                                {(action.tooltip && !actionDisabled(action)) && <Tooltip title={action.tooltip} arrow>
                                    <Button
                                        key={index}
                                        variant={action.variant ? action.variant : "outlined"}
                                        color={action.color ? action.color : "primary"}
                                        disabled={actionDisabled(action)}
                                        onClick={action.onClick}
                                        endIcon={action.icon ? action.icon : null}
                                    >
                                        {action.label}
                                    </Button>
                                </Tooltip>}
                                {(!action.tooltip || actionDisabled(action)) && <Button
                                    key={index}
                                    variant={action.variant ? action.variant : "outlined"}
                                    color={action.color ? action.color : "primary"}
                                    disabled={actionDisabled(action)}
                                    onClick={action.onClick}
                                    endIcon={action.icon ? action.icon : null}
                                >
                                    {action.label}
                                </Button>}
                            </React.Fragment>
                        ))}
                    </ButtonGroup>}
                 

                </Stack>
            </Grid>
            {pagination && <Grid item xs={4}>
                <Table sx={{ p: 0, m: 0 }}>
                    <TableBody sx={{ p: 0, m: 0 }}>
                        <TableRow sx={{ p: 0, m: 0 }}>
                            <TablePagination
                                sx={{ p: 0, m: 0 }}
                                count={countForPagination}
                                onPageChange={(e, p) => setPage(p)}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[rowsPerPage]}
                                showFirstButton
                                showLastButton
                                labelDisplayedRows={({ from, to, count }) => { return `${from}–${to} מתוך ${count !== -1 ? count : `more than ${to}`}`; }}
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>}
        </Grid>
    );
}

const Row = ({ row, index, columns }) => {

    const theme = useTheme();

    const { getLookup } = useContext(Context);

    const [openCollapse, setOpenCollapse] = useState(false);
    const [objCollapse, setObjCollapse] = useState(null);
    const [copyed, setCopyed] = useState(false);
    const isCollapsed = columns.some(column => column.collapse);
    const collapseContent = isCollapsed ? columns.find(column => column.collapse).collapse : null;

    const actionDisabled = (action) => {
        if (action.disabled) {
            if (typeof action.disabled === 'function') {
                if (action.disabled(row)) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return action.disabled;
    }

    const actionLabel = (action) => {
        if (action.label) {
            if (typeof action.label === 'function') {
                return action.label(row);
            } else {
                return action.label;
            }
        }
        return "";
    }

    const switchRow = (col) => {
        if (col.key) {
            switch (col.type) {
                case "date":
                    if (row[col.key] === null || row[col.key] === undefined || row[col.key] === "") return "-";
                    return formatDate(row[col.key]);
                case "month":
                    if (row[col.key] === null || row[col.key] === undefined || row[col.key] === "") return "-";
                    return formatMonth(row[col.key]);
                case "datetime":
                    if (row[col.key] === null || row[col.key] === undefined || row[col.key] === "") return "-";
                    return <>
                        <Typography variant="body2">
                            {formatDateTimeObj(row[col.key]).date}
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={0.5} sx={{width:1, height:1}}>
                            <AccessTimeFilledIcon sx={{ fontSize: 13 }} />
                            <Typography variant='caption' sx={{ fontSize: "85%" }}>
                                {formatDateTimeObj(row[col.key]).time}
                            </Typography>
                        </Stack>
                    </>;
                case "currency":
                    return formatCurrency(row[col.key]);
                case "currencyIL":
                    return formatCurrencyIL(row[col.key]);
                case "minusCurrencyIL":
                    return formatMinusCurrencyIL(row[col.key]);
                case "percent":
                    return formatPercent(row[col.key]);
                case "lookup":
                    if (row[col.key] == null || row[col.key] == undefined || row[col.key] == "") return "-";
                    // //console.log(getLookup(col.lookup).find(item => item.value === row[col.key]).label);
                    return getLookup(col.lookup).find(item => item.value === row[col.key]).label;
                // return row[col.key];
                case "boolean":
                    const v = <Typography variant="body1" color="success.main" sx={{fontWeight:700}}>✓</Typography>;
                    const x = <Typography variant="body1" color="error" sx={{fontWeight:700}}>✗</Typography>;
                    switch (row[col.key]) {
                        case true:
                            return v;
                        case false:
                            return x;
                        case 'true':
                            return v;
                        case 'false':
                            return x;
                        case 'כן':
                            return v;
                        case 'לא':
                            return x;
                        case '1':
                            return v;
                        case '0':
                            return x;
                        case 1:
                            return v;
                        case 0:
                            return x;
                        default:
                            return row[col.key];
                    }
                case "small":
                    return <span style={{ fontSize: "80%" }}>{row[col.key]}</span>;
                case "readmore":
                    return <Tooltip title={row[col.key] || ''} arrow>
                        <Typography variant="body2">{formatReadMore(row[col.key])}</Typography>
                    </Tooltip>;
                case "readmore-copy":
                    return <Tooltip title={
                        <>
                            <Typography variant="body1">
                                {copyed ? "הועתק ללוח !" : "ניתן ללחוץ כדי להעתיק:"}
                            </Typography>
                            <Typography variant="body2">{row[col.key]}</Typography>
                        </>
                    } arrow>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{ m: 0, p: 0 }}
                            onClick={() => {
                                navigator.clipboard.writeText(row[col.key]);
                                setCopyed(true);
                                setTimeout(() => {
                                    setCopyed(false);
                                }, 2000);
                            }}
                        >
                            {formatReadMore(row[col.key])}
                        </Button>
                    </Tooltip>;
                case "copy":
                    return <Tooltip title={
                        <>
                            <Typography variant="body1">
                                {copyed ? "הועתק ללוח !" : "ניתן ללחוץ כדי להעתיק"}
                            </Typography>
                        </>
                    } arrow>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{ m: 0, p: 0, ...col.sx }}
                            onClick={() => {
                                navigator.clipboard.writeText(row[col.key]);
                                setCopyed(true);
                                setTimeout(() => {
                                    setCopyed(false);
                                }, 2000);
                            }}
                        >
                            {row[col.key]}
                        </Button>
                    </Tooltip>;
                default:
                    return row[col.key];
            }
        }
        if (col.cb) return col.cb({row, setOpenCollapse, openCollapse, index, objCollapse, setObjCollapse});
        if (col.actions) {
            return (
                <ButtonGroup size='small'>
                    {col.actions.map((action, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            color="primary"
                            disabled={actionDisabled(action)}
                            onClick={() => action.onClick({row, setOpenCollapse})}
                            endIcon={action.icon ? action.icon : null}
                            sx={{ py: 0, fontSize: "85%" }}
                        >
                            {actionLabel(action)}
                        </Button>
                    ))}
                </ButtonGroup>
            )
        }
        return row[col.key];
    }

    return (
        <>
            <TableRow hover>
                {columns.map((col, index) => (
                    <TableCell key={index}
                        sx={{
                            borderBottom: isCollapsed ? 'unset' : '',
                            bgcolor: (isCollapsed && openCollapse) ? theme.palette.primary.lighter : col.bgcolor ? col.bgcolor : null,
                            color: col.color ? col.color : null,
                        }}>
                        {switchRow(col)}
                    </TableCell>
                ))}
            </TableRow>
            {isCollapsed && <TableRow>
                <TableCell colSpan={26} sx={{ fontSize: "100%", bgcolor: openCollapse ? theme.palette.primary.lighter : null }}>
                    <Collapse in={openCollapse} timeout={250} sx={{ textAlign: "left", my: openCollapse ? 2 : 0, mx: 2 }}>
                        {collapseContent({row, setOpenCollapse, openCollapse, index, objCollapse, setObjCollapse})}
                    </Collapse>
                </TableCell>
            </TableRow>}
        </>
    )
}
const GenericTable = ({ columns, data, title, footer, actions,counter,  pagination, rowsPerPage, page, setPage, titleBgColor, titleColor }) => {

    const [isPending, startTransition] = useTransition();
    const [dataToShow, setDataToShow] = useState([]);
    const [isStart, setIsStart] = useState(true);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        setIsStart(false);
        startTransition(() => {
            setDataToShow(data);
        });
    }, [data]);

    const count = () => {
        if (!counter) return false;
        if (typeof counter === 'boolean' && counter) return dataToShow.length || 0;
        return counter;
    }

    const countForPagination = () => {
        if (!pagination) return 0;
        if (!counter) return dataToShow ? dataToShow.length : 0;
        return Number(counter);
    }

    return (
        <>
            {(title || actions || counter) && <Header
                title={title}
                actions={actions}
                count={count()}
                countForPagination={countForPagination()}
                rowsPerPage={rowsPerPage}
                page={page}
                setPage={setPage}
            />}
            <TableContainer sx={{ height: 1, pb:10 }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            {columns.map((column, index) => (
                                <TableCell key={index} sx={{ bgcolor: titleBgColor, color: titleColor }}>
                                    {isFunction(column.label) ? column.label(setDataToShow) : column.label}
                                    {/* <TableSortLabel /> */}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isPending || isStart) && <TableRow>
                            <TableCell colSpan={1000}>
                                <Box sx={{ width: '50%', m: "50px auto" }}>
                                    <LinearProgress />
                                </Box>
                            </TableCell>
                        </TableRow>}
                        {(dataToShow && !isPending && !isStart) && dataToShow.map((row, index) => {
                            return (
                                <Row key={index} index={index} row={row} columns={columns} />
                            )
                        })}
                    </TableBody>
                </Table>
                {footer}
            </TableContainer>
        </>
    )
}

GenericTable.defaultProps = {
    title: null,
    footer: null,
    actions: null,
    counter: null,
    pagination: false,
    rowsPerPage: 50,
    page: 0,
    setPage: null,
    titleBgColor: null,
    titleColor: null
}

export default GenericTable;

//check if is string
function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

// check if is function
function isFunction(value) {
    return typeof value === 'function';
}
