import React from 'react';

import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import GenericInput from './GenericInput';
import GenericSelect from './GenericSelect';
import GenericCheckbox from './GenericCheckbox';
import GenericAutocomplete from './GenericAutocomplete';

import Context from '../../context';

const GenericForm = ({ fields, onSubmit, initInputs, setInitInput, border, allFieldsSetting, readonly, isForm, spacing }) => {

    const { getLookup, getLookupAutocomplete } = React.useContext(Context);

    const [inputs, setInputs] = React.useState(initInputs);
    React.useEffect(() => {
        setInputs(initInputs);
    }, [initInputs]);
    // React.useEffect(() => {
    //     if (setInitInput) {
    //         console.log('setInitInput');
    //         setInitInput(inputs)
    //     }
    // }, [setInputs]);

    const handleChange = (e) => {
        if (readonly) return;

        if (e.target.type === "checkbox") {
            if (setInitInput) setInitInput({ ...inputs, [e.target.name]: e.target.checked })
            setInputs({ ...inputs, [e.target.name]: e.target.checked });
            return;
        }

        let value = e.target.value;
        if (e.target.type === "number") value = Number(value);
        if (e.target.type === "month") value = new Date(value + "-01");
        if (setInitInput) {
            setInitInput({ ...inputs, [e.target.name]: value })
        }
        setInputs({ ...inputs, [e.target.name]: value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(inputs);
    }

    const fixColSize = (field) => {
        let size = field.size ? field.size : 12;
        if (isObject(size)) {
            return size;
        } else {
            return { xs: size };
        }
    }

    const switchField = (field) => {
        if (field.displayCondition && !field.displayCondition(inputs)) return null;
        if (field.cb) return field.cb({ inputs, setInputs });
        switch (field.type) {
            case 'empty':
                return <Box />;
            case 'line':
                return <>
                    <Divider sx={{ mt: 1 }} />
                    {field.label && <Typography
                        variant='subtitle2'
                        color='primary'
                    >
                        {field.label}
                    </Typography>}
                </>;
            case 'group':
                return (
                    <>
                        {field.title && <Typography variant='subtitle2'>{field.title}</Typography>}
                        <Box sx={{
                            width: 1,
                            border: field.border ? '1px solid #f2f2f2' : 'none',
                            borderRadius: '4px',
                            p: field.border ? 2 : 0,
                        }}>
                            <Grid container spacing={2}>
                                {field.fields.map((fieldG, indexG) => {
                                    fieldG = { ...fieldG, allFieldsSetting: allFieldsSetting };
                                    return (
                                        <Grid item {...fixColSize(fieldG)} key={indexG}>
                                            {switchField(fieldG)}
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Box>
                    </>
                )
            case 'title':
                return <Typography variant="h6" align='center'>{field.label}</Typography>;
            case 'select':
                return <GenericSelect {...field} inputs={inputs} handleChange={handleChange} />;
            case 'lookup':
                if (field.parentID) {
                    // console.log(field.parentID);
                    if (inputs[field.parentID] == 0 || inputs[field.parentID] == null) {
                        field.options = [];
                    } else {
                        field.options = getLookup(field.lookup, field.parentID ? inputs[field.parentID] : null);
                    }
                } else {
                    field.options = getLookup(field.lookup, null);
                }
                return <GenericSelect {...field} inputs={inputs} handleChange={handleChange} />;
            case 'lookup-autocomplete':
                field.options = getLookupAutocomplete(field.lookup, field.parentID ? inputs[field.parentID] : null);
                return <GenericAutocomplete {...field} inputs={inputs} handleChange={setInputs} />;
            case 'checkbox':
                return <GenericCheckbox {...field} inputs={inputs} handleChange={handleChange} />;
            case 'checkboxGroup':
                return (
                    <>
                        {field.fields.map((fieldG, indexG) => {
                            fieldG = { ...fieldG, allFieldsSetting: allFieldsSetting };
                            return <GenericCheckbox key={indexG} {...fieldG} inputs={inputs} handleChange={handleChange} />;
                        })}
                    </>
                )
            case 'submit':
                return <Button size='large' disabled={field.disabled ? field.disabled : null} fullWidth variant={field.variant ? field.variant : "text"} type='submit'>{field.label || 'שליחת טופס'}</Button>;
            case 'reset':
                return <Button color={field.color?field.color:"error"}  endIcon={<DeleteIcon fontSize='small' />}  fullWidth variant={field.variant ? field.variant : "text"} type='reset'>{field.label || 'ניקוי טופס'}</Button>;
            case 'button':
                return <Button fullWidth type='button' onClick={() => {
                    if (!field.click) return;
                    field.click(inputs, setInputs);
                }}>{field.label || 'כפתור'}</Button>;
            case 'textarea':
                return <GenericInput {...field} multiline={true} inputs={inputs} handleChange={handleChange} setInputs={setInputs} />;
            default:
                return <GenericInput {...field} inputs={inputs} handleChange={handleChange} setInputs={setInputs} />;
        }
    }

    return (
        <Box component={isForm ? 'form' : 'div'}
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    return;
                }
            }}
            sx={{
                border: border ? '1px solid #ccc' : 'none',
                borderRadius: '4px',
                p: border ? '1rem' : '0',
            }}>
            <Grid container spacing={spacing}>
                {fields.map((field, index) => {
                    if (field.displayConditionGrid && !field.displayConditionGrid(inputs)) return null;
                    field = { ...field, allFieldsSetting: allFieldsSetting };
                    return (
                        <Grid item {...fixColSize(field)} key={index}>
                            {switchField(field)}
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    )
}

GenericForm.defaultProps = {
    initInputs: {},
    allFieldsSetting: {},
    border: false,
    readonly: false,
    isForm: true,
    onSubmit: (inputs) => { console.log("submit", inputs); },
    setInitInput: null,
    spacing: 2
}

export default GenericForm;

function isObject(obj) {
    return obj === Object(obj);
}
