// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 3,
                    paddingLeft: 10,
                    paddingRight: 10,
                    textAlign: "center",
                    borderColor: theme.palette.divider,
                    color: theme.palette.secondary.main,
                    fontWeight: 400,
                },
                head: {
                    backgroundColor : theme.palette.primary.main,
                    color: theme.palette.primary.contrastText,
                    textAlign: "center",
                    lineHeight: 3,
                    fontWeight: 500,
                    paddingTop: 5,
                    paddingBottom: 5
                }
            }
        }
    };
}
