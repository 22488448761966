import React from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';

import { Grid, Stack, Button, ToggleButtonGroup, ToggleButton, IconButton, Card } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';

import http from '../../../api/http';
import Context from '../../../context';
import { formatDateJS } from '../../../utils/func';
import GenericForm from '../../../components/GenericForm';

const RowCollaapse = ({ row, googleAddress }) => {

    const queryClient = useQueryClient();
    const { snackbar, confirm, popup } = React.useContext(Context);

    const locationID = useQuery(['customerLocationID'], () => http.get('locations/customer'));
    const address_details = React.useMemo(() => row.address_details == '' ? ["", "", ""] : JSON.parse(row.address_details), [row]);

    const [edit, setEdit] = React.useState(false);
    const [inputsState, setInputsState] = React.useState({
        d_date: formatDateJS(),
        packeges: 1,
        collect: 0,
        type: 1,
        name: row.name,
        phone: row.phone.replace(/-/g, ''),
        note: clearUndefined(row.note),
        area: googleAddress.area,
        street: googleAddress.street,
        num: googleAddress.num,
        city: googleAddress.city
    });
    React.useEffect(() => {
        setInputsState({...inputsState, floor: address_details[1].toString(), appartment: address_details[2].toString()});
    }, [address_details]);

    const addLocation = useMutation((obj) => http.post('locations', obj));
    const updateDeliveryApi = useMutation((obj) => http.put(`deliveries/external/singel/${row.id}/${obj.done}`), {
        onSuccess: (data) => {
            queryClient.refetchQueries(['deliveriesFromApi'], { active: true, exact: true });
            snackbar(`המשלוח עודכן בהצלחה`, 'success');
        },
        onError: (error) => {
            snackbar("אירעה שגיאה בעדכון המשלוח", 'error');
        }
    });
    const addDelivery = useMutation((obj) => http.post('deliveries', obj), {
        onSuccess: () => {
            updateDeliveryApi.mutate({ done: 1 });
        },
        onError: () => {
            snackbar("אירעה שגיאה ביצירת המשלוח", "error");
        }
    });

    const fields = [
        {
            type: "group",
            size: {xs:12,md:6,lg:4},
            fields: [
                { type: "line", label: "פרטים כלליים" },
                {
                    size: {xs:12,md:6,lg:4},
                    cb: () => <ToggleButtonGroup
                        color="primary"
                        name="type"
                        value={inputsState.type.toString()}
                        exclusive
                        fullWidth
                        onChange={(e, v) => setInputsState({ ...inputsState, type: v })}
                    >
                        <ToggleButton sx={{ p: 1 }} name='type' value='1' >רגיל</ToggleButton>
                        <ToggleButton sx={{ p: 1 }} name='type' value='2'>אקספרס</ToggleButton>
                        <ToggleButton sx={{ p: 1 }} name='type' value='3'>כפול</ToggleButton>
                    </ToggleButtonGroup>
                },
                { name: 'd_date', label: 'תאריך איסוף', type: 'date',size: {xs:12,md:6,lg:4}, inputProps: { min: formatDateJS(row.creation) } },
                { name: 'packeges', type: 'number', label: 'מספר חבילות',  size: {xs:12,md:6,lg:4}, inputProps: { min: 1 } },
                { name: 'collect', type: 'currencyIL', label: 'גוביינא', size: {xs:12,md:6,lg:4},inputProps: { min: 0 } },

            ]
        },
        {
            type: "group",
            size: {xs:12,md:6,lg:4},
            fields: [
                { type: "line", label: "פרטי כתובת יעד" },
                { name: 'area', type: 'text', label: "אזור",size: {xs:12,md:6} },
                { name: 'city', type: 'text', label: "עיר", size: {xs:12,md:6}, required: true, error: isEmpty(inputsState.city) },
                { name: 'street', label: "רחוב", type: 'text', required: true, error: isEmpty(inputsState.street) },
                { name: 'num', type: 'text', label: "מספר בית",size: {xs:12,md:6, lg:3}, required: true, error: isEmpty(inputsState.num) },
                { name: 'floor', type: 'text', label: "קומה", size: {xs:12,md:6, lg:3} },
                { name: 'appartment', type: 'text', label: "דירה",size: {xs:12,md:6, lg:3} },
                { name: 'entrance', type: 'text', label: "כניסה", size: {xs:12,md:6, lg:3} },
            ]
        },
        {
            type: "group",
            size: {xs:12,md:6, lg:4},
            fields: [
                { type: "line", label: "פרטי איש קשר" },
                { name: 'name', label: "שם", type: 'text',size: {xs:12,md:6, lg:4}, required: true, error: isEmpty(inputsState.name) },
                { name: 'phone', type: 'text', label: "טלפון",  size: {xs:12,md:6, lg:4}, required: true, error: isEmpty(inputsState.phone) || !phoneILValidator(inputsState.phone), errorText: "מספר ישראלי בלבד" },
                { name: 'phone1', type: 'text', label: "טלפון נוסף", size: {xs:12,md:6, lg:4}},
                { name: 'note', type: 'text', label: "הערות" },
            ]
        }
    ];

    const submit = async () => {

        if (isEmpty(inputsState.city)
            || isEmpty(inputsState.street)
            || isEmpty(inputsState.num)
            || isEmpty(inputsState.name)
            || isEmpty(inputsState.phone)
            || !phoneILValidator(inputsState.phone)) return;

        if (!locationID.isSuccess) {
            snackbar("אירעה שגיאה בקבלת מוצא", "error");
            return;
        };

        let newLocation = {
            name: inputsState.name,
            area: inputsState.area,
            city: inputsState.city,
            street: inputsState.street,
            num: inputsState.num,
            floor: inputsState.floor,
            appartment: inputsState.appartment,
            entrance: inputsState.entrance,
            note: inputsState.note,
            contact: inputsState.name,
            phone: inputsState.phone,
            phone2: inputsState.phone2
        };
        let newDelivery = {
            d_from: locationID.data.location_id,
        };

        try {
            const res = await addLocation.mutateAsync(newLocation);
            newDelivery.d_to = res;
        } catch (error) {
            snackbar("תקלה בשמירת יעד", "error");
            return;
        }

        newDelivery = {
            ...newDelivery,
            d_date: inputsState.d_date,
            type: inputsState.type,
            collect: inputsState.collect,
            packeges: inputsState.packeges,
            comment: inputsState.note
        }

        addDelivery.mutate(newDelivery);

    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Card elevation={0} sx={{ p: 2 }}>
                    <GenericForm
                        fields={fields}
                        initInputs={inputsState}
                        setInitInput={setInputsState}
                        spacing={6}
                    />
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Stack direction='row' justifyContent="end" spacing={2}>
                    <Button disabled={updateDeliveryApi.isLoading || addDelivery.isLoading} variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => {
                        updateDeliveryApi.mutate({ done: 3 });
                    }}>ביטול</Button>
                    <Button disabled={updateDeliveryApi.isLoading || addDelivery.isLoading} variant="contained" color="success" startIcon={<SaveIcon />} onClick={() => {
                        submit();
                    }}>אישור</Button>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default RowCollaapse;

// check if if value is empty
const isEmpty = (value) => {
    return value == undefined || value == '' || value == null ? true : false;
}

function phoneILValidator(input) {

    let removedHyphens = input.replace(/-/g, '');//remove all hyphens
    let numbersRegex = new RegExp('^[0-9]*$');//regex for only numbers
    const isOnlyNumber = numbersRegex.test(removedHyphens);

    const isTenChar = removedHyphens.length === 9 || removedHyphens.length === 10; //numbers only equal to 10
    if (isOnlyNumber && isTenChar) {
        return true;
    } else {
        return false;
    }
}

function clearUndefined(str) {
    return str.replace(/undefined/g, '');
}
