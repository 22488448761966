import React from "react";

import Context from "./index";
import usePopups from "./hooks/popups";
import useLookup from "./hooks/lookup/useLookup";
import useAuth from "./hooks/auth/useAuth";

import NoData from "../components/NoData";

// create a context provider
const ContextProvider = ({ children }) => {

    const { user, setUser, companies, setCompanies, isAuth } = useAuth();

    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [snackbarMessageType, setSnackbarMessageType] = React.useState("success");
    const [snackbarMessage, setSnackbarMessage] = React.useState("");

    const snackbar = (message = "Message", type = 'success') => {
        setSnackbarMessageType(type);
        setSnackbarMessage(message);
        setOpenSnackbar(true);
    }

    const [title, setTitle] = React.useState('');

    const popups = usePopups();
    const { getLookup, getObj } = useLookup();



    const store = {
        openSnackbar,
        setOpenSnackbar,
        snackbarMessage,
        snackbarMessageType,
        snackbar,
        ...popups,
        getLookup, getObj,
        user, setUser, companies, setCompanies, isAuth,
        title, setTitle
    }

    if (isAuth === 1) {
        return <NoData />;
    }

    return (
        <Context.Provider value={store}>
            {children}
        </Context.Provider>
    );
}
export default ContextProvider;