import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import http from "../../../api/http";

function useAuth() {

    const navigate = useNavigate();

    const [isLocalStoragToken, setIsLocalStoragToken] = React.useState(false);
    const [isAuth, setIsAuth] = React.useState(1);
    const [user, setUser] = React.useState(null);
    const [companies, setCompanies] = React.useState(null);

    React.useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            setIsLocalStoragToken(true);
            setIsAuth(1);
        } else {
            setIsLocalStoragToken(false);
            setIsAuth(2);
        }
    }, []);

    // כאשר יהיה באתר דפים שפתוחים לכלל הציבור, כדאי יהיה לא לבצע פנייה לשרת במקרה שאין נתוני משתמש בלוקאל סטורג
    const { data, isLoading, isError } = useQuery(
        ['User'],
        async () => await http.get("user"),
        {
            enabled: !!isLocalStoragToken,
        }
    );

    // console.log("data", data);

    React.useEffect(() => {
        if (data) {
            setIsAuth(3);
            setUser(data);
            if(data.companies.length > 1) {
                setCompanies(data.companies);
            }
        }
        if(isError) {
            setIsAuth(2);
        }
    }, [data, isError]);

    return { user, setUser, companies, setCompanies, isAuth };

}
export default useAuth;