// ==============================|| OVERRIDES - BADGE ||============================== //

export default function Tooltip(theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor : theme.palette.primary.main,
                },
                arrow:{
                    color: theme.palette.primary.main,
                }
            }
        }
    };
}
