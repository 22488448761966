import React from 'react';

export const usePopups = () => {

    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [objConfirm, setObjConfirm] = React.useState(false);
    const confirm = (obj) => {
        setObjConfirm(obj);
        setOpenConfirm(true);
    };

    const [openPopup, setOpenPopup] = React.useState(false);
    const [propsPopup, setPropsPopup] = React.useState({}); // props
    const [titlePopup, setTitlePopup] = React.useState('פופאפ');
    const [childrenPopup, setChildrenPopup] = React.useState(null); // content
    const closePopupCallback = React.useRef(null);
    const popup = ({title, content, props, onClose}) => {
        setPropsPopup(props)
        setTitlePopup(title);
        setChildrenPopup(content);
        setOpenPopup(true);
        closePopupCallback.current = onClose ? onClose : null;
    };
    const closePopup = () => {
        if(closePopupCallback.current !== null) {
            closePopupCallback.current();
        }
        setOpenPopup(false);
    };
    React.useEffect(() => {
        if(!openPopup) {
            setPropsPopup({});
            setTitlePopup('פופאפ');
            setChildrenPopup(null);
        }
    }, [openPopup]);

    const [openSmallPopup, setOpenSmallPopup] = React.useState(false);
    const [propsSmallPopup, setPropsSmallPopup] = React.useState({}); // props
    const [titleSmallPopup, setTitleSmallPopup] = React.useState('פופאפ');
    const [childrenSmallPopup, setChildrenSmallPopup] = React.useState(null); // content
    const closePopupSmallCallback = React.useRef(null);
    const smallPopup = ({title, content, props, onClose}) => {
        setPropsSmallPopup(props)
        setTitleSmallPopup(title);
        setChildrenSmallPopup(content);
        setOpenSmallPopup(true);
        closePopupSmallCallback.current = onClose ? onClose : null;
    };
    const closeSmallPopup = () => {
        if(closePopupSmallCallback.current !== null) {
            closePopupSmallCallback.current();
        }
        setOpenSmallPopup(false);
    };
    React.useEffect(() => {
        if(!openSmallPopup) {
            setPropsSmallPopup({});
            setTitleSmallPopup('פופאפ');
            setChildrenSmallPopup(null);
        }
    }, [openSmallPopup]);


    return {
        openConfirm, setOpenConfirm, objConfirm, setObjConfirm, confirm,
        popup, closePopup, openPopup, setOpenPopup, titlePopup, setTitlePopup, childrenPopup, setChildrenPopup, propsPopup, setPropsPopup,
        smallPopup, closeSmallPopup, openSmallPopup, setOpenSmallPopup, titleSmallPopup, setTitleSmallPopup, childrenSmallPopup, setChildrenSmallPopup, propsSmallPopup, setPropsSmallPopup
    }

}
export default usePopups;