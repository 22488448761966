import React from 'react'

import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const GenericCheckbox = ({ name, label, options, allFieldsSetting, inputs, handleChange }) => {

    const theme = useTheme();

    const checked = () => {
        if (inputs[name]) {
            switch (inputs[name]) {
                case 'true':
                    return true;
                case 'false':
                    return false;
                case '1':
                    return true;
                case '0':
                    return false;
                default:
                    return true;
            }
        }
        return false;
    }

    return (
        <FormControl fullWidth {...allFieldsSetting}>
            <FormControlLabel {...allFieldsSetting}
                control={<Checkbox
                    size='small'
                    {...allFieldsSetting}
                    name={name}
                    checked={checked()}
                    onChange={handleChange}
                />}
                label={label}
            />
        </FormControl>
    )
}

GenericCheckbox.defaultProps = {
    options: [],
}

export default GenericCheckbox;