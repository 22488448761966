import React from 'react';
import { useNavigate } from "react-router-dom";
import { useMutation } from 'react-query';

import { Button, Container, Grid, Typography, Card } from '@mui/material';

import FormHeader from './FormHeader';
import Location from './Location';
import http from '../../../api/http';
import Context from '../../../context';
import { formatDateJS } from '../../../utils/func';
import GenericInput from '../../../components/GenericForm/GenericInput';


const DeliveryForm = () => {

  let navigate = useNavigate();
  const { snackbar,user } = React.useContext(Context);

  const [delivery, setDelivery] = React.useState({
    d_date: formatDateJS(),
    type: 1,
    collect: 0,
    packeges: 1,
    comment: ''
  });

  const [from, setFrom] = React.useState({
    id: 0,
  });
  const [to, setTo] = React.useState({
    id: 0,
  });

  // console.log(from, to);

  const [fromErrors, setFromErrors] = React.useState({});
  const [toErrors, setToErrors] = React.useState({});
  // console.log(fromErrors, toErrors);


  const addLocation = useMutation((obj) => http.post('locations', obj));
  const addDelivery = useMutation((obj) => http.post('deliveries', obj), {
    onSuccess: (data) => {
      snackbar('המשלוח נוצר בהצלחה', 'success');
      if (user.role == 'customer')
      navigate('/customer');
      else  
      navigate('/company/deliveries');
    },
    onError: (error) => {
      snackbar('התרחשה שגיאה', 'error');
    }
  });

  const submit = async () => {

    if (from.id == undefined || to.id == undefined) return;

    let fromObj = {};
    let toObj = {};

    if (from.id == 0) {
      if (isEmpty(from.name)) fromObj = { ...fromObj, name: true };
      if (isEmpty(from.city)) fromObj = { ...fromObj, city: true };
      if (isEmpty(from.street)) fromObj = { ...fromObj, street: true };
      if (isEmpty(from.num)) fromObj = { ...fromObj, num: true };
      if (isEmpty(from.contact)) fromObj = { ...fromObj, contact: true };
      if (isEmpty(from.phone) || !phoneILValidator(from.phone)) fromObj = { ...fromObj, phone: true };
      setFromErrors(fromObj);
    }

    if (to.id == 0) {
      if (isEmpty(to.name)) toObj = { ...toObj, name: true };
      if (isEmpty(to.city)) toObj = { ...toObj, city: true };
      if (isEmpty(to.street)) toObj = { ...toObj, street: true };
      if (isEmpty(to.num)) toObj = { ...toObj, num: true };
      if (isEmpty(to.contact)) toObj = { ...toObj, contact: true };
      if (isEmpty(to.phone) || !phoneILValidator(to.phone)) toObj = { ...toObj, phone: true };
      setToErrors(toObj);
    }

    if (Object.keys(fromObj).length > 0 || Object.keys(toObj).length > 0) return;

    let deliveryObj = {
      d_from: from.id,
      d_to: to.id,
      d_date: delivery.d_date,
      type: delivery.type,
      collect: delivery.collect,
      packeges: delivery.packeges,
      comment: delivery.comment
    };

    if (from.id == 0) {
      try {
        const { id, ...toObj } = from;
        const res = await addLocation.mutateAsync(toObj);
        deliveryObj = { ...deliveryObj, d_from: res };
      } catch (error) {
        // console.log(error);
        snackbar("תקלה בשמירת מקום", "error");
        return;
      }
    }

    if (to.id == 0) {
      try {
        const { id, ...toObj } = to;
        const res = await addLocation.mutateAsync(toObj);
        deliveryObj = { ...deliveryObj, d_to: res };
      } catch (error) {
        // console.log(error);
        snackbar("תקלה בשמירת מקום", "error");
        return;
      }
    }

    addDelivery.mutate(deliveryObj);

  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" component="h1" gutterBottom color="primary" align='center'>
            משלוח חדש
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormHeader delivery={delivery} setDelivery={setDelivery} />
        </Grid>
        <Grid item xs={6}>
          <Location type='from' location={from} setLocation={setFrom} errors={fromErrors} setErrors={setFromErrors} />
        </Grid>
        <Grid item xs={6}>
          <Location type='to' location={to} setLocation={setTo} errors={toErrors} setErrors={setToErrors} />
        </Grid>
        <Grid item xs={8}>
          <Card elevation={0} sx={{ p: 2 }}>
            <GenericInput
              label="הערה כללית למשלוח"
              name="comment"
              multiline={true}
              inputs={delivery}
              handleChange={(e) => setDelivery({ ...delivery, comment: e.target.value })}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Button variant="contained" fullWidth size='large' onClick={submit} disabled={addLocation.isLoading || addDelivery.isLoading}>יצירת משלוח</Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default DeliveryForm;

// check if if value is empty
const isEmpty = (value) => {
  return value == undefined || value == '' || value == null ? true : false;
}

function phoneILValidator(input) {

  let removedHyphens = input.replace(/-/g, '');//remove all hyphens
  let numbersRegex = new RegExp('^[0-9]*$');//regex for only numbers
  const isOnlyNumber = numbersRegex.test(removedHyphens);

  const isTenChar = removedHyphens.length === 9 || removedHyphens.length === 10; //numbers only equal to 10
  if (isOnlyNumber && isTenChar) {
    return true;
  } else {
    return false;
  }
}