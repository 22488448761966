import React from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { Grid, Stack, Button } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import http from '../../../api/http';
import Context from '../../../context';



const RowCollaapse = ({ row }) => {

    // console.log(row);

    const queryClient = useQueryClient();
    const { snackbar, confirm, popup } = React.useContext(Context);


    const cancelDelivery = useMutation((obj) => http.put("deliveries/cancel/" + row.id), {
        onSuccess: () => {
            queryClient.refetchQueries(['deliveriesForCompany'], { active: true, exact: true });
            snackbar("המשלוח בוטל בהצלחה", "success");
        },
        onError: () => {
            snackbar("אירעה שגיאה בביטול המשלוח", "error");
        }
    });
    const updateDelivery = useMutation((obj) => http.put("deliveries/update/" + row.id), {
        onSuccess: () => {
            queryClient.refetchQueries(['deliveriesForCompany'], { active: true, exact: true });
            snackbar("המשלוח עודכן בהצלחה", "success");
        },
        onError: () => {
            snackbar("אירעה שגיאה בעידכון המשלוח", "error");
        }
    });
    const updateDeliveryToCome = useMutation((obj) => http.put("deliveries/updateToCome/" + row.id), {
        onSuccess: () => {
            queryClient.refetchQueries(['deliveriesForCompany'], { active: true, exact: true });
            snackbar("המשלוח עודכן בהצלחה", "success");
        },
        onError: () => {
            snackbar("אירעה שגיאה בעידכון המשלוח", "error");
        }
    });


    return (
        <Grid container spacing={2}>

            <Grid item xs={12}>
                <Stack direction='row' justifyContent="end" spacing={2}>
                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => {
                        confirm({
                            message: "האם אתה בטוח שברצונך לבטל את המשלוח?",
                            onConfirm: () => {
                                cancelDelivery.mutate();
                            }
                        })
                    }}>ביטול</Button>
                    {row.status == 2 && <Button variant="contained" color="primary" onClick={() => {
                        updateDelivery.mutate();
                    }}>נאסף</Button>}

                    {(row.status == 2 || row.status == 3) && <Button variant="contained" color="success" onClick={() => {
                        updateDeliveryToCome.mutate();
                    }}>הגיע ליעד</Button>}
                    {row.collect > 0 && <Button variant="contained" color="secondary" onClick={() => {
                        // updateDeliveryToCome.mutate();
                    }}>גוביינא</Button>}


                </Stack>
            </Grid>
        </Grid>
    )
}

export default RowCollaapse