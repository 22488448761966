import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import { Alert, Button, Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';

import GenericForm from '../../../components/GenericForm';
import GenericSelect from '../../../components/GenericForm/GenericSelect';

import Context from '../../../context';
import http from '../../../api/http';

const Auth = () => {

    let navigate = useNavigate();
    const { user, setUser, companies, setCompanies } = React.useContext(Context);

    // React.useEffect(() => {
    //     if (user) {
    //         navigate('/customer');
    //     }
    // }, [user, navigate]);

    const [chooseCompany, setChooseCompany] = React.useState(false);
    const [inputs, setInputs] = React.useState({});

    const companiesList = React.useMemo(() => {
        if (companies) {
            return companies.map(c => ({ value: c.id, label: c.name }));
        } else {
            return [];
        }
    }, [companies]);

    const userInConrtext = useMutation(() => http.get('user'), {
        onSuccess: async (res) => {
            let user = await res;
            setUser(user);
            switch (user.role) {
                case 'customer':
                    navigate('/customer');
                    break;
                case 'manager':
                    navigate('/company/deliveries');
                    break;
                case 'courier':
                    navigate('/courier');
                    break;
                default:
                    navigate('/');
                    break;
            }
        },
        onError: (error) => {
            console.log('error', error);
        }
    });

    const login = useMutation(obj => {
        return http.post('login', obj);
    }, {
        onSuccess: (res) => {
            localStorage.setItem('token', res.token);
            if (res.companies.length > 1) {
                setCompanies(res.companies);
                return setChooseCompany(true);
            } else {
                setCompanies(null);
                userInConrtext.mutate();
            };
        },
        onError: (error) => {
            console.log('error', error);
        },
    });

    return (
        <Stack direction="row" justifyContent='center' alignItems='center' sx={{ width: 1, height: "80vh" }}>
            <Card elevation={24} sx={{ width: { xs: 0.9, sm: 400 }, py: 3, borderRadius: 3 }}>
                <Typography variant='h3' align='center' color='primary'>
                    {chooseCompany ? "בחירת חברה" : "כניסה למערכת"}
                </Typography>
                {user && <CardContent>
                    <Button fullWidth variant='contained' color='primary' onClick={() => {
                        switch (user.role) {
                            case 'customer':
                                navigate('/customer');
                                break;
                            case 'manager':
                                navigate('/company/deliveries');
                                break;
                            case 'courier':
                                navigate('/courier');
                                break;
                            default:
                                navigate('/');
                                break;
                        }
                    }}>כניסה</Button>
                </CardContent>}
                {!user && <CardContent>
                    {(login.isError || userInConrtext.isError) && <Alert severity="error" sx={{ mb: 2 }}>
                        שם משתמש או סיסמא שגויים
                    </Alert>}
                    {!chooseCompany && <GenericForm
                        fields={[
                            { label: "שם משתמש", name: "username", type: "text" },
                            { label: "סיסמא", name: "password", type: "password" },
                            { type: "submit", label: "כניסה", variant: "contained", disabled: login.isLoading },
                        ]}
                        onSubmit={(inputs) => {
                            login.mutate(inputs);
                        }}
                    />}
                    {chooseCompany && <GenericSelect
                        label="חברה"
                        name="company"
                        options={companiesList}
                        inputs={{ company: 0 }}
                        handleChange={(e) => {
                            localStorage.setItem('uc', e.target.value);
                            userInConrtext.mutate();
                        }}
                    />}
                </CardContent>}
            </Card>
        </Stack>
    )
}

export default Auth